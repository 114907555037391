var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "update-body" },
      [
        _c(
          "el-card",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
          },
          [
            _c("div", { staticClass: "button-flag" }, [
              _c(
                "div",
                { staticStyle: { display: "flex", "margin-bottom": "2rem" } },
                [
                  _c("div", { staticClass: "button-group" }, [
                    _c(
                      "span",
                      {
                        class: _vm.buttonFlag == 1 ? "active" : "",
                        on: {
                          click: function ($event) {
                            return _vm.toProcessRelationship(1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.standardProcess")))]
                    ),
                    _c(
                      "span",
                      {
                        class: _vm.buttonFlag == 2 ? "active" : "",
                        on: {
                          click: function ($event) {
                            return _vm.toProcessRelationship(2)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.originalProcess")))]
                    ),
                  ]),
                  _c("div", { staticClass: "vertical-line" }, [_vm._v("|")]),
                  _c("div", { staticClass: "button-group" }, [
                    _c(
                      "span",
                      {
                        class:
                          _vm.parentFlag == 1 || _vm.noTreeFromCsvOrApi
                            ? "active"
                            : "",
                        on: {
                          click: function ($event) {
                            return _vm.toParentchildRelationship(1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.noParent")))]
                    ),
                    _c(
                      "span",
                      {
                        class: _vm.parentFlag == 2 ? "active" : "",
                        on: {
                          click: function ($event) {
                            return _vm.toParentchildRelationship(2)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.parentExists")))]
                    ),
                  ]),
                ]
              ),
              _vm.parentFlag == 2 && _vm.copyFlag
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        height: "22px",
                        "border-left": "5px solid #BFEBFA",
                        "margin-left": "20px",
                        display: "flex",
                        "align-items": "center",
                        "font-size": "14px",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("messages.callDataPorduct"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("div", [
              _c(
                "div",
                { staticClass: "top-seihin-box" },
                [
                  _c(
                    "div",
                    { staticClass: "seihin-name-select" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "seihinNameObjName",
                          attrs: {
                            model: _vm.seihinNameObjName,
                            rules: _vm.seihinNameRules,
                            "validate-on-rule-change": false,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("messages.addproductName"),
                                prop: "seihinName",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "select-item",
                                  attrs: {
                                    size: "small",
                                    filterable: "",
                                    disabled: !_vm.statusDisabled,
                                  },
                                  on: { change: _vm.choseSeihinName },
                                  model: {
                                    value: _vm.seihinNameObjName.seihinName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.seihinNameObjName,
                                        "seihinName",
                                        $$v
                                      )
                                    },
                                    expression: "seihinNameObjName.seihinName",
                                  },
                                },
                                _vm._l(
                                  _vm.filterSeihinNameList,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.seihincode,
                                      attrs: {
                                        label: item.seihinname,
                                        value: item.seihinname,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "seihin-unit" },
                    [
                      _c(
                        "el-form",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("messages.unit") } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { size: "small", disabled: "" },
                                  model: {
                                    value: _vm.choseSeihinUnit,
                                    callback: function ($$v) {
                                      _vm.choseSeihinUnit = $$v
                                    },
                                    expression: "choseSeihinUnit",
                                  },
                                },
                                _vm._l(_vm.seihinUnitOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.unitValue,
                                    attrs: {
                                      label: item.unitLabel,
                                      value: item.unitValue,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "seihin-version" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "seihinNameObjVersion",
                          attrs: {
                            model: _vm.seihinNameObjVersion,
                            rules: _vm.seihinNameRules,
                            "validate-on-rule-change": false,
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("messages.version"),
                                prop: "seihinversion",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: !_vm.statusDisabled,
                                  size: "small",
                                },
                                model: {
                                  value: _vm.seihinNameObjVersion.seihinversion,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.seihinNameObjVersion,
                                      "seihinversion",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "seihinNameObjVersion.seihinversion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "seihin-btn" },
                    [
                      _c(
                        "el-form",
                        [
                          _c(
                            "el-form-item",
                            { staticClass: "padding-top-20" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn-color",
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editContent()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.checkRegister"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "edit-icon" },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            disabled: _vm.statusDisabled,
                          },
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.simulateNote,
                                expression: "simulateNote",
                              },
                            ],
                            attrs: {
                              cols: "40",
                              rows: "10",
                              maxlength: "3000",
                              placeholder: _vm.$t("messages.note"),
                            },
                            domProps: { value: _vm.simulateNote },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.simulateNote = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "icon-size",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c("img", {
                                style: {
                                  cursor: _vm.statusDisabled
                                    ? "not-allowed"
                                    : "pointer",
                                },
                                attrs: {
                                  src: require("@/assets/static/icon/noteAdd.svg"),
                                  width: "16",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.buttonFlag != 2
                    ? _c(
                        "div",
                        {
                          staticClass: "edit-icon",
                          style: {
                            cursor: _vm.statusDisabled
                              ? "not-allowed"
                              : "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.triggerHelpDialog()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "icon-size",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/static/icon/addMenu.svg"),
                                  height: "16",
                                },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: _vm.$t("messages.entryAssistMenu"),
                        visible: _vm.dialogVisible,
                        width: "30%",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            width: "85%",
                            title: _vm.$t("messages.utilityDistribution"),
                            visible: _vm.innerVisibleSort1,
                            center: "",
                            "append-to-body": "",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.innerVisibleSort1 = $event
                            },
                          },
                        },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "utilityApportionmentForm",
                              attrs: {
                                model: _vm.utilityApportionmentForm,
                                rules: _vm.utilityApportionmentRules,
                                "validate-on-rule-change": false,
                              },
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 2 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("messages.itemName"),
                                            prop: "selectIdeaInfo",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "width-100",
                                              attrs: {
                                                size: "small",
                                                placeholder:
                                                  "項目名を選択してください",
                                              },
                                              on: {
                                                change: function (val) {
                                                  return _vm.getSelectIdeaInfo(
                                                    "utility",
                                                    val
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.utilityApportionmentForm
                                                    .selectIdeaInfo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.utilityApportionmentForm,
                                                    "selectIdeaInfo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "utilityApportionmentForm.selectIdeaInfo",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "messages.electricity"
                                                  ),
                                                  value: "1",
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "messages.waterSuplly"
                                                  ),
                                                  value: "2",
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label:
                                                    _vm.$t("messages.fuel"),
                                                  value: "3",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 5 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("messages.efName"),
                                            prop: "ideaName",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "width-100",
                                              attrs: {
                                                filterable: "",
                                                size: "small",
                                                placeholder:
                                                  "原単位名を選択してください",
                                              },
                                              on: {
                                                change:
                                                  _vm.utilityApportionmentNameChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.utilityApportionmentForm
                                                    .ideaName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.utilityApportionmentForm,
                                                    "ideaName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "utilityApportionmentForm.ideaName",
                                              },
                                            },
                                            _vm._l(
                                              _vm.utilityApportionmentList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.ideacode,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.ideacode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 7 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("messages.opuc"),
                                            prop: "consumption",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              size: "small",
                                              placeholder:
                                                _vm.$t("messages.opuc"),
                                            },
                                            on: {
                                              input: function (value) {
                                                return (_vm.utilityApportionmentForm.consumption =
                                                  value
                                                    .replace(
                                                      /(\-?)\D*(\d*)(\.?)(\d*)\d*/,
                                                      "$1$2$3$4"
                                                    )
                                                    .match(
                                                      /^\-?\d*(\.?\d*)/g
                                                    )[0] || "")
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.utilityApportionmentForm
                                                  .consumption,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.utilityApportionmentForm,
                                                  "consumption",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "utilityApportionmentForm.consumption",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 2 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("messages.unit"),
                                            prop: "unit",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              size: "small",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.utilityApportionmentForm
                                                  .unit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.utilityApportionmentForm,
                                                  "unit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "utilityApportionmentForm.unit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "messages.overallPlantProduction"
                                            ),
                                            prop: "production",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "product-value" },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  size: "small",
                                                  placeholder: _vm.$t(
                                                    "messages.overallPlantProduction"
                                                  ),
                                                },
                                                on: {
                                                  input: function (value) {
                                                    return (_vm.utilityApportionmentForm.production =
                                                      value
                                                        .replace(
                                                          /\D*(\d*)(\.?)(\d*)\d*/,
                                                          "$1$2$3"
                                                        )
                                                        .match(
                                                          /^\d*(\.?\d*)/g
                                                        )[0] || "")
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.utilityApportionmentForm
                                                      .production,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.utilityApportionmentForm,
                                                      "production",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "utilityApportionmentForm.production",
                                                },
                                              }),
                                              _c("span", [_vm._v("kg")]),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "messages.productWeight"
                                            ),
                                            prop: "productWeight",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "product-value" },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  size: "small",
                                                  placeholder: _vm.$t(
                                                    "messages.productWeight"
                                                  ),
                                                },
                                                on: {
                                                  input: function (value) {
                                                    return (_vm.utilityApportionmentForm.productWeight =
                                                      value
                                                        .replace(
                                                          /\D*(\d*)(\.?)(\d*)\d*/,
                                                          "$1$2$3"
                                                        )
                                                        .match(
                                                          /^\d*(\.?\d*)/g
                                                        )[0] || "")
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.utilityApportionmentForm
                                                      .productWeight,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.utilityApportionmentForm,
                                                      "productWeight",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "utilityApportionmentForm.productWeight",
                                                },
                                              }),
                                              _c("span", [_vm._v("kg")]),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("messages.amount"),
                                            prop: "quantity",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              size: "small",
                                              placeholder:
                                                _vm.$t("messages.amount"),
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.utilityApportionmentForm
                                                  .quantity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.utilityApportionmentForm,
                                                  "quantity",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "utilityApportionmentForm.quantity",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 2 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("messages.unit"),
                                            prop: "unit",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              size: "small",
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.utilityApportionmentForm
                                                  .unit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.utilityApportionmentForm,
                                                  "unit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "utilityApportionmentForm.unit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  on: { click: _vm.toBackUtilityApportionment },
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.back")))]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.insertUtilityApportionment },
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.doregister")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            width: "85%",
                            title: _vm.$t("messages.productTransport"),
                            visible: _vm.innerVisibleSort2,
                            center: "",
                            "append-to-body": "",
                            "close-on-click-modal": false,
                            "close-on-press-escape": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.innerVisibleSort2 = $event
                            },
                          },
                        },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "seihinyusoForm",
                              attrs: {
                                model: _vm.seihinyusoForm,
                                rules: _vm.seihinyusoRules,
                                "validate-on-rule-change": false,
                              },
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("messages.itemName"),
                                            prop: "itemName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "messages.productTransport"
                                              ),
                                              size: "small",
                                            },
                                            model: {
                                              value:
                                                _vm.seihinyusoForm.itemName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.seihinyusoForm,
                                                  "itemName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "seihinyusoForm.itemName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "messages.transportMeans"
                                            ),
                                            prop: "trafficName",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "width-100",
                                              attrs: {
                                                placeholder:
                                                  _vm.$t("messages.petm"),
                                                size: "small",
                                              },
                                              on: {
                                                change: _vm.trafficNameChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.seihinyusoForm
                                                    .trafficName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.seihinyusoForm,
                                                    "trafficName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "seihinyusoForm.trafficName",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "messages.roadTransportOnly"
                                                  ),
                                                  value: "1",
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "messages.includingSeaTransport"
                                                  ),
                                                  value: "2",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("messages.ef"),
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              size: "small",
                                              title:
                                                _vm.seihinyusoForm
                                                  .originalUnit1,
                                              disabled: "",
                                            },
                                            model: {
                                              value:
                                                _vm.seihinyusoForm
                                                  .originalUnit1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.seihinyusoForm,
                                                  "originalUnit1",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "seihinyusoForm.originalUnit1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.trafficSeaItemShow
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      size: "small",
                                                      title:
                                                        _vm.seihinyusoForm
                                                          .originalUnit2,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.seihinyusoForm
                                                          .originalUnit2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.seihinyusoForm,
                                                          "originalUnit2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "seihinyusoForm.originalUnit2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      size: "small",
                                                      title:
                                                        _vm.seihinyusoForm
                                                          .originalUnit3,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.seihinyusoForm
                                                          .originalUnit3,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.seihinyusoForm,
                                                          "originalUnit3",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "seihinyusoForm.originalUnit3",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "messages.transportScenario"
                                            ),
                                            prop: "transSenario1",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass:
                                                "select-item width-100",
                                              attrs: {
                                                size: "small",
                                                placeholder: _vm.$t(
                                                  "messages.productTransport"
                                                ),
                                                filterable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.seihinyusoForm
                                                    .transSenario1,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.seihinyusoForm,
                                                    "transSenario1",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "seihinyusoForm.transSenario1",
                                              },
                                            },
                                            _vm._l(
                                              _vm.transSenarioOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.senarioid,
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "messages." +
                                                        "item" +
                                                        item.senarioid
                                                    ),
                                                    value: item.senarioid + "",
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.trafficSeaItemShow
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      size: "small",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.seihinyusoForm
                                                          .transSenario2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.seihinyusoForm,
                                                          "transSenario2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "seihinyusoForm.transSenario2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: "transSenario3",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticClass:
                                                        "select-item width-100",
                                                      attrs: {
                                                        size: "small",
                                                        placeholder:
                                                          _vm.$t(
                                                            "messages.pets"
                                                          ),
                                                        filterable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.seihinyusoForm
                                                            .transSenario3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.seihinyusoForm,
                                                            "transSenario3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "seihinyusoForm.transSenario3",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.transSenarioOptions,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.senarioid,
                                                          attrs: {
                                                            label:
                                                              item.senarioname,
                                                            value:
                                                              item.senarioid,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "is-required",
                                          attrs: {
                                            label: _vm.$t(
                                              "messages.transportDistance"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "product-value" },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  size: "small",
                                                  disabled: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.seihinyusoForm
                                                      .trafficDistance1,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.seihinyusoForm,
                                                      "trafficDistance1",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "seihinyusoForm.trafficDistance1",
                                                },
                                              }),
                                              _c("span", [_vm._v("km")]),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm.trafficSeaItemShow
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: "trafficDistance2",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "product-value",
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          size: "small",
                                                        },
                                                        on: {
                                                          input: function (
                                                            value
                                                          ) {
                                                            return (_vm.seihinyusoForm.trafficDistance2 =
                                                              value
                                                                .replace(
                                                                  /\D*(\d*)(\.?)(\d*)\d*/,
                                                                  "$1$2$3"
                                                                )
                                                                .match(
                                                                  /^\d*(\.?\d*)/g
                                                                )[0] || "")
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.seihinyusoForm
                                                              .trafficDistance2,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.seihinyusoForm,
                                                              "trafficDistance2",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "seihinyusoForm.trafficDistance2",
                                                        },
                                                      }),
                                                      _c("span", [
                                                        _vm._v("km"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c("el-form-item", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "product-value",
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        size: "small",
                                                        disabled: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.seihinyusoForm
                                                            .trafficDistance3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.seihinyusoForm,
                                                            "trafficDistance3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "seihinyusoForm.trafficDistance3",
                                                      },
                                                    }),
                                                    _c("span", [_vm._v("km")]),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("messages.amount"),
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "product-value" },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "0",
                                                  size: "small",
                                                  disabled: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.seihinyusoForm
                                                      .quantity1,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.seihinyusoForm,
                                                      "quantity1",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "seihinyusoForm.quantity1",
                                                },
                                              }),
                                              _c("span", [_vm._v("tkm")]),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm.trafficSeaItemShow
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-form-item", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "product-value",
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder: "0",
                                                        size: "small",
                                                        disabled: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.seihinyusoForm
                                                            .quantity2,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.seihinyusoForm,
                                                            "quantity2",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "seihinyusoForm.quantity2",
                                                      },
                                                    }),
                                                    _c("span", [_vm._v("tkm")]),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _c("el-form-item", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "product-value",
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder: "0",
                                                        size: "small",
                                                        disabled: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.seihinyusoForm
                                                            .quantity3,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.seihinyusoForm,
                                                            "quantity3",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "seihinyusoForm.quantity3",
                                                      },
                                                    }),
                                                    _c("span", [_vm._v("tkm")]),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "messages.productWeight"
                                            ),
                                            prop: "productWeight",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "product-value" },
                                            [
                                              _c("el-input", {
                                                attrs: { size: "small" },
                                                on: {
                                                  input: function (value) {
                                                    return (_vm.seihinyusoForm.productWeight =
                                                      value
                                                        .replace(
                                                          /\D*(\d*)(\.?)(\d*)\d*/,
                                                          "$1$2$3"
                                                        )
                                                        .match(
                                                          /^\d*(\.?\d*)/g
                                                        )[0] || "")
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.seihinyusoForm
                                                      .productWeight,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.seihinyusoForm,
                                                      "productWeight",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "seihinyusoForm.productWeight",
                                                },
                                              }),
                                              _c("span", [_vm._v("kg")]),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.doBackSeihinyuso()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.back")))]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.insertSeihinyuso()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.doregister")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "content-center" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-color",
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.innerVisibleSort1 = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("messages.utilityDistribution"))
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-color",
                              attrs: { size: "small", type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.innerVisibleSort2 = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("messages.productTransport"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer content-center",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn-plain",
                              on: {
                                click: function ($event) {
                                  _vm.dialogVisible = false
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("messages.back")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.parentFlag == 1 && !_vm.noTreeFromCsvOrApi
                    ? _c("div", { staticClass: "emissionc-sum" }, [
                        _c("div", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("messages.sumemissionc")) + " "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "1.6rem !important",
                              "background-color": "#E7E6E6",
                              "font-weight": "1000",
                              display: "inline-block",
                              "line-height": "2.5",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.sumProcessEmission[0]) +
                                _vm._s(_vm.sumProcessEmission[1])
                            ),
                            _c("sup", [
                              _vm._v(_vm._s(_vm.sumProcessEmission[2])),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.parentFlag == 2 || _vm.noTreeFromCsvOrApi
                    ? _c("div", { staticClass: "emissionc-sum" }, [
                        _c("div", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("messages.sumemissionc")) + " "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "1.6rem !important",
                              "background-color": "#E7E6E6",
                              "font-weight": "1000",
                              display: "inline-block",
                              "line-height": "2.5",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.sumTreeEmission[0]) +
                                _vm._s(_vm.sumTreeEmission[1])
                            ),
                            _c("sup", [_vm._v(_vm._s(_vm.sumTreeEmission[2]))]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "bom-import rightBtn" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "bomImport-button",
                            attrs: {
                              disabled: _vm.statusDisabled,
                              type: "primary",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openpdf()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("messages.viewGuidelines")))]
                        ),
                        _c(
                          "el-upload",
                          {
                            ref: "uploadButton",
                            staticStyle: {
                              display: "inline-flex",
                              "margin-left": "16px",
                            },
                            attrs: {
                              disabled: _vm.statusDisabled,
                              "show-file-list": false,
                              accept: ".*",
                              name: "csvFile",
                              headers: _vm.headers,
                              "on-progress": _vm.showPageLoading,
                              "on-success": _vm.onSuccess,
                              "on-error": _vm.onError,
                              action: _vm.actionup,
                              data: _vm.uploadData,
                            },
                          },
                          [
                            _vm.fromPage !== "externalApiPage" &&
                            _vm.parentFlag !== 2
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "bomImport-button",
                                    attrs: {
                                      slot: "tip",
                                      type: "primary",
                                      size: "small",
                                      disabled: _vm.statusDisabled,
                                      icon: _vm.importDataBtnIcon,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.checkExistData()
                                      },
                                    },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("messages.uploadCSV")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-upload",
                          {
                            ref: "treeUploadButton",
                            staticStyle: { display: "inline-flex" },
                            attrs: {
                              disabled: _vm.statusDisabled,
                              "show-file-list": false,
                              accept: ".*",
                              name: "csvFile",
                              headers: _vm.headers,
                              "on-progress": _vm.showPageLoading,
                              "on-success": _vm.treeOnSuccess,
                              "on-error": _vm.treeOnError,
                              action: _vm.actionup,
                              data: _vm.treeUploadData,
                            },
                          },
                          [
                            _vm.fromPage !== "externalApiPage" &&
                            _vm.parentFlag === 2
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "bomImport-button",
                                    attrs: {
                                      slot: "tip",
                                      type: "primary",
                                      size: "small",
                                      disabled: _vm.statusDisabled,
                                      icon: _vm.importDataBtnIcon,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.treeCheckExistData()
                                      },
                                    },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("messages.uploadCSV")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "btn-function margin-top-5" }, [
                      _vm.fromPage !== "externalApiPage" && _vm.parentFlag !== 2
                        ? _c("div", { on: { click: _vm.downloalNoParent } }, [
                            _c("img", {
                              staticClass: "download-icon",
                              attrs: {
                                src: require("@/assets/static/icon/download.svg"),
                              },
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("messages.formatNoParent"))),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.fromPage !== "externalApiPage" && _vm.parentFlag === 2
                        ? _c("div", { on: { click: _vm.downloalParent } }, [
                            _c("img", {
                              staticClass: "download-icon",
                              attrs: {
                                src: require("@/assets/static/icon/download.svg"),
                              },
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("messages.formatParent"))),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        title: "原単位と活動量の確認・修正",
                        visible: _vm.resultsDialogVisible,
                        "close-on-click-modal": false,
                        width: "97%",
                        center: "",
                        "before-close": _vm.handleDialogClose,
                        "lock-scroll": false,
                        "append-to-body": true,
                        "modal-append-to-body": false,
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.resultsDialogVisible = $event
                        },
                      },
                    },
                    [
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            width: "85%",
                            title: "",
                            visible: _vm.updateVisible,
                            "append-to-body": "",
                            center: "",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.updateVisible = $event
                            },
                          },
                        },
                        [
                          _c("h6", { staticClass: "content-title" }, [
                            _vm._v(_vm._s(_vm.$t("messages.tfihbl2anef"))),
                            _c("br"),
                            _vm._v(_vm._s(_vm.$t("messages.pttiyw2urarutnef"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "table-border table margin-top-32" },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.changedCsvTableData,
                                    "lock-scroll": false,
                                    "append-to-body": true,
                                    "tooltip-effect": "dark",
                                    height: "380",
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      width: "90",
                                      label: "test",
                                      "class-name":
                                        _vm.language === "en"
                                          ? "enStyle"
                                          : "jpStyle",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "itemA",
                                      label: _vm.$t("messages.itemA"),
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.itemA)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "itemB",
                                      label: _vm.$t("messages.itemB"),
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.itemB)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "itemC",
                                      label: _vm.$t("messages.itemC"),
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.itemC)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "itemD",
                                      label: _vm.$t("messages.itemD"),
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.itemD)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "itemE",
                                      label: _vm.$t("messages.itemE"),
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(scope.row.itemE)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "processCode",
                                      label: _vm.$t("messages.processAfter"),
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.processCode)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "processCodeOld",
                                      label: _vm.$t("messages.processBefore"),
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.processCodeOld)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "ideaName",
                                      label: _vm.$t("messages.afterUpdate"),
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.ideaName)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "ideaNameOld",
                                      label: _vm.$t("messages.beforUpdate"),
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.ideaNameOld)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.updateVisible = false
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("messages.back")))]
                              ),
                              _vm.language === "jp"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.updateDataToAdd },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("messages.onlyEmissions")
                                        ) + _vm._s(_vm.$t("messages.register"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.language === "en"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.updateDataToAdd },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("messages.reo1")))]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.updateRuleDisabled,
                                  },
                                  on: { click: _vm.updateRule },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("messages.addRuleButton"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "1%" },
                          attrs: { type: "primary" },
                          on: { click: _vm.rowToAdd },
                        },
                        [_vm._v("行追加")]
                      ),
                      _c("div", { staticClass: "table-border table" }, [
                        _c(
                          "section",
                          { staticClass: "header" },
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "table-header",
                                attrs: { gutter: 0 },
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      "min-height": "1px",
                                      width: "4%",
                                    },
                                  },
                                  [_c("div", { staticClass: "blank-div" })]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    style: {
                                      width: _vm.parentFlag !== 2 ? "8%" : "6%",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "is-required" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v(_vm._s(_vm.$t("messages.item"))),
                                  ]
                                ),
                                _c("el-col", { staticStyle: { width: "4%" } }, [
                                  _vm._v(_vm._s(_vm.$t("messages.itemA"))),
                                ]),
                                _c("el-col", { staticStyle: { width: "4%" } }, [
                                  _vm._v(_vm._s(_vm.$t("messages.itemB"))),
                                ]),
                                _c("el-col", { staticStyle: { width: "4%" } }, [
                                  _vm._v(_vm._s(_vm.$t("messages.itemC"))),
                                ]),
                                _c("el-col", { staticStyle: { width: "4%" } }, [
                                  _vm._v(_vm._s(_vm.$t("messages.itemD"))),
                                ]),
                                _c("el-col", { staticStyle: { width: "4%" } }, [
                                  _vm._v(_vm._s(_vm.$t("messages.itemE"))),
                                ]),
                                _c(
                                  "el-col",
                                  {
                                    style: {
                                      width: _vm.parentFlag !== 2 ? "8%" : "6%",
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "is-required" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v(_vm._s(_vm.$t("messages.process"))),
                                  ]
                                ),
                                _c("el-col", { staticStyle: { width: "8%" } }, [
                                  _vm._v(_vm._s(_vm.$t("messages.efName"))),
                                ]),
                                _c("el-col", { staticStyle: { width: "8%" } }, [
                                  _c("span", { staticClass: "is-required" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v(_vm._s(_vm.$t("messages.amount"))),
                                  _c("br"),
                                  _vm._v(
                                    "(" +
                                      _vm._s(_vm.$t("messages.finalized")) +
                                      ")"
                                  ),
                                ]),
                                _vm.parentFlag !== 2
                                  ? _c(
                                      "el-col",
                                      { staticStyle: { width: "8%" } },
                                      [
                                        _vm._v(_vm._s(_vm.$t("messages.unit"))),
                                        _c("br"),
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.$t("messages.ef")) +
                                            ")"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.parentFlag == 2
                                  ? _c(
                                      "el-col",
                                      { staticStyle: { width: "7%" } },
                                      [
                                        _vm._v(_vm._s(_vm.$t("messages.unit"))),
                                        _c("br"),
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.$t("messages.ef")) +
                                            ")"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-col",
                                  {
                                    style: {
                                      width: _vm.parentFlag !== 2 ? "8%" : "7%",
                                    },
                                  },
                                  [
                                    _vm._v(_vm._s(_vm.$t("messages.amount"))),
                                    _c("br"),
                                    _vm._v(
                                      "(" +
                                        _vm._s(_vm.$t("messages.inputData")) +
                                        ")"
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    style: {
                                      width: _vm.parentFlag !== 2 ? "8%" : "7%",
                                    },
                                  },
                                  [
                                    _vm._v(_vm._s(_vm.$t("messages.unit"))),
                                    _c("br"),
                                    _vm._v(
                                      "(" +
                                        _vm._s(_vm.$t("messages.inputData")) +
                                        ")"
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    style: {
                                      width: _vm.parentFlag !== 2 ? "8%" : "7%",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("messages.intermediateProduct")
                                      )
                                    ),
                                  ]
                                ),
                                _vm.parentFlag === 2
                                  ? _c(
                                      "el-col",
                                      { staticStyle: { width: "8%" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("messages.parentItem"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("el-col", { staticStyle: { width: "8%" } }, [
                                  _vm._v(_vm._s(_vm.$t("messages.yieldRate"))),
                                ]),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      "min-height": "1px",
                                      width: "4%",
                                    },
                                  },
                                  [_c("div", { staticClass: "blank-div" })]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "table-body-scroll" },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "csvTableDataForm",
                                attrs: { model: _vm.csvTableDataForm },
                                nativeOn: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              _vm._l(
                                _vm.csvTableDataForm.csvTableData,
                                function (csvItem, csvItemIndex) {
                                  return _c(
                                    "div",
                                    { key: csvItemIndex },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticClass: "table-body",
                                          attrs: { gutter: 0 },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass:
                                                "table-col col-width",
                                              staticStyle: { width: "4%" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "padding-top-14",
                                                },
                                                [
                                                  !(
                                                    csvItemIndex !== 0 &&
                                                    csvItem.index ===
                                                      _vm.csvTableData[
                                                        csvItemIndex - 1
                                                      ].index
                                                  )
                                                    ? _c("el-checkbox", {
                                                        staticStyle: {
                                                          "margin-right": "4px",
                                                        },
                                                        on: {
                                                          change: function (
                                                            checked
                                                          ) {
                                                            return _vm.checkCsvListChange(
                                                              checked,
                                                              csvItemIndex
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            csvItem.checkCsvFlag,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              csvItem,
                                                              "checkCsvFlag",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "csvItem.checkCsvFlag",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              class:
                                                _vm.parentFlag !== 2
                                                  ? "table-bg-white col-flex"
                                                  : "table-bg-white",
                                              staticStyle: { width: "8%" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    [
                                                      !(
                                                        csvItemIndex !== 0 &&
                                                        csvItem.index ===
                                                          _vm.csvTableData[
                                                            csvItemIndex - 1
                                                          ].index
                                                      )
                                                        ? _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "messages.item"
                                                                ),
                                                              size: "small",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.inputItemNameChange(
                                                                  csvItem.itemName,
                                                                  csvItemIndex
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                csvItem.itemName,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    csvItem,
                                                                    "itemName",
                                                                    _vm._n($$v)
                                                                  )
                                                                },
                                                              expression:
                                                                "csvItem.itemName",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  !(
                                                    csvItemIndex !== 0 &&
                                                    csvItem.index ===
                                                      _vm.csvTableData[
                                                        csvItemIndex - 1
                                                      ].index
                                                  ) && csvItem.itemName === ""
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#F56C6C",
                                                            "font-size": "12px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "管理項目を設定してください"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass:
                                                "table-col col-width",
                                              staticStyle: { width: "4%" },
                                            },
                                            [
                                              !(
                                                csvItemIndex !== 0 &&
                                                csvItem.index ===
                                                  _vm.csvTableData[
                                                    csvItemIndex - 1
                                                  ].index
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "padding-top-14",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(csvItem.itemA)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass:
                                                "table-col col-width",
                                              staticStyle: { width: "4%" },
                                            },
                                            [
                                              !(
                                                csvItemIndex !== 0 &&
                                                csvItem.index ===
                                                  _vm.csvTableData[
                                                    csvItemIndex - 1
                                                  ].index
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "padding-top-14",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(csvItem.itemB)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass:
                                                "table-col col-width",
                                              staticStyle: { width: "4%" },
                                            },
                                            [
                                              !(
                                                csvItemIndex !== 0 &&
                                                csvItem.index ===
                                                  _vm.csvTableData[
                                                    csvItemIndex - 1
                                                  ].index
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "padding-top-14",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(csvItem.itemC)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass:
                                                "table-col col-width",
                                              staticStyle: { width: "4%" },
                                            },
                                            [
                                              !(
                                                csvItemIndex !== 0 &&
                                                csvItem.index ===
                                                  _vm.csvTableData[
                                                    csvItemIndex - 1
                                                  ].index
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "padding-top-14",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(csvItem.itemD)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass:
                                                "table-col col-width",
                                              staticStyle: { width: "4%" },
                                            },
                                            [
                                              !(
                                                csvItemIndex !== 0 &&
                                                csvItem.index ===
                                                  _vm.csvTableData[
                                                    csvItemIndex - 1
                                                  ].index
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "padding-top-14",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(csvItem.itemE)
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "table-col special",
                                              staticStyle: { width: "8%" },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  key: csvItemIndex,
                                                  attrs: {
                                                    prop:
                                                      "csvTableData." +
                                                      csvItemIndex +
                                                      ".processCode",
                                                    rules: {
                                                      required: true,
                                                      message:
                                                        "プロセスを選択してください",
                                                      trigger: [
                                                        "blur",
                                                        "change",
                                                      ],
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: { size: "small" },
                                                      model: {
                                                        value:
                                                          csvItem.processCode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            csvItem,
                                                            "processCode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "csvItem.processCode",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.csvProcessNameListOptions,
                                                      function (item, i) {
                                                        return _c("el-option", {
                                                          key:
                                                            item.processCode +
                                                            i,
                                                          attrs: {
                                                            label:
                                                              item.processCode,
                                                            value:
                                                              item.processCode,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "table-bg-white",
                                              staticStyle: { width: "8%" },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  key: csvItemIndex,
                                                  attrs: {
                                                    prop:
                                                      "csvTableData." +
                                                      csvItemIndex +
                                                      ".ideaName",
                                                  },
                                                },
                                                [
                                                  _c("el-autocomplete", {
                                                    staticClass:
                                                      "genntani-input",
                                                    attrs: {
                                                      size: "small",
                                                      "fetch-suggestions":
                                                        _vm.querySearchFile,
                                                      placeholder:
                                                        _vm.$t(
                                                          "messages.efName"
                                                        ),
                                                      "trigger-on-focus": false,
                                                      title: csvItem.ideaName,
                                                    },
                                                    on: {
                                                      select: function (item) {
                                                        _vm.handleSelectFile(
                                                          item,
                                                          csvItemIndex
                                                        )
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.inputChangeFile(
                                                          csvItem.ideaName,
                                                          csvItemIndex
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var item = ref.item
                                                            return [
                                                              _c(
                                                                "el-tooltip",
                                                                {
                                                                  staticClass:
                                                                    "item",
                                                                  attrs: {
                                                                    effect:
                                                                      "dark",
                                                                    placement:
                                                                      "top",
                                                                    content:
                                                                      item.name,
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value: csvItem.ideaName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          csvItem,
                                                          "ideaName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "csvItem.ideaName",
                                                    },
                                                  }),
                                                  csvItem.delflg == 1 &&
                                                  csvItem.ideaName !== "" &&
                                                  _vm.ideaNameDataList[
                                                    csvItemIndex
                                                  ] === csvItem.ideaName
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#F56C6C",
                                                            "font-size": "12px",
                                                            "line-height": "1",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "この原単位は削除されています"
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass:
                                                "table-bg-white col-flex",
                                              staticStyle: { width: "8%" },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  key: csvItemIndex,
                                                  attrs: {
                                                    prop:
                                                      "csvTableData." +
                                                      csvItemIndex +
                                                      ".quantity",
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message:
                                                          _vm.$t(
                                                            "messages.peaam"
                                                          ),
                                                        trigger: [
                                                          "blur",
                                                          "change",
                                                        ],
                                                      },
                                                      {
                                                        validator:
                                                          _vm.checkInput01,
                                                        trigger: [
                                                          "blur",
                                                          "change",
                                                        ],
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        _vm.$t(
                                                          "messages.amount"
                                                        ),
                                                      size: "small",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.handleQuantityChange(
                                                          csvItem,
                                                          csvItemIndex,
                                                          $event
                                                        )
                                                      },
                                                      blur: function ($event) {
                                                        return _vm.changeChackStatus(
                                                          csvItemIndex
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: csvItem.quantity,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          csvItem,
                                                          "quantity",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "csvItem.quantity",
                                                    },
                                                  }),
                                                  !csvItem.chackStatus &&
                                                  csvItem.quantity != "" &&
                                                  csvItem.ideaName != "" &&
                                                  csvItem.hasChangeIdeaName
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#E6A23C",
                                                            "font-size": "12px",
                                                            "line-height": "1",
                                                            "padding-top":
                                                              "4px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "messages.checkAmount"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : !csvItem.chackStatus &&
                                                      !(
                                                        csvItem.unitList || []
                                                      ).some(function (item) {
                                                        return (
                                                          csvItem.importUnit ===
                                                          item.tounit
                                                        )
                                                      }) &&
                                                      csvItem.ideaName &&
                                                      csvItem.quantity != ""
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "txt",
                                                          staticStyle: {
                                                            color: "#E6A23C",
                                                            "line-height": "1",
                                                            "padding-top":
                                                              "4px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "messages.unitMismatched"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "table-col special",
                                              staticStyle: { width: "8%" },
                                            },
                                            [
                                              csvItem.ideaName
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                size: "small",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.unitCsvChange(
                                                                      csvItem.unit,
                                                                      csvItem.unitList,
                                                                      csvItemIndex
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  csvItem.unit,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      csvItem,
                                                                      "unit",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "csvItem.unit",
                                                              },
                                                            },
                                                            _vm._l(
                                                              csvItem.unitList,
                                                              function (
                                                                item,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: i,
                                                                    attrs: {
                                                                      value:
                                                                        item.percent +
                                                                        "/unitlabel/" +
                                                                        item.tounit,
                                                                      label:
                                                                        item.tounit,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !csvItem.ideaName
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                size: "small",
                                                              },
                                                              model: {
                                                                value:
                                                                  csvItem.unit,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      csvItem,
                                                                      "unit",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "csvItem.unit",
                                                              },
                                                            },
                                                            [
                                                              _c("el-option", {
                                                                attrs: {
                                                                  value: "",
                                                                  label: "",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "table-col",
                                              staticStyle: { width: "8%" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "padding-top-14",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      csvItem.importQuantity
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "table-col",
                                              staticStyle: { width: "8%" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "padding-top-14",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(csvItem.importUnit)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "table-bg-white",
                                              staticStyle: { width: "8%" },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: "interimProduct",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "messages.intermediateProduct"
                                                      ),
                                                      size: "small",
                                                    },
                                                    model: {
                                                      value:
                                                        csvItem.interimProduct,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          csvItem,
                                                          "interimProduct",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "csvItem.interimProduct",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.parentFlag === 2
                                            ? _c(
                                                "el-col",
                                                {
                                                  staticClass: "table-bg-white",
                                                  staticStyle: { width: "8%" },
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      key: csvItemIndex,
                                                      attrs: {
                                                        prop:
                                                          "csvTableData." +
                                                          csvItemIndex +
                                                          ".parentNode",
                                                        rules: [
                                                          {
                                                            required: false,
                                                            trigger: "blur",
                                                          },
                                                          {
                                                            validator:
                                                              _vm.checkCsvParentnode1,
                                                            trigger: "blur",
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder: _vm.$t(
                                                            "messages.parentItem"
                                                          ),
                                                          size: "small",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeCsvTempArr(
                                                              csvItem
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            csvItem.parentNode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              csvItem,
                                                              "parentNode",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "csvItem.parentNode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          csvItem.processCode === "A1" ||
                                          csvItem.processCode === "A2" ||
                                          csvItem.processCode === "A3" ||
                                          csvItem.genProcess === "A1" ||
                                          csvItem.genProcess === "A2" ||
                                          csvItem.genProcess === "A3"
                                            ? _c(
                                                "el-col",
                                                {
                                                  staticClass: "table-bg-white",
                                                  staticStyle: { width: "8%" },
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      key: csvItemIndex,
                                                      attrs: {
                                                        prop:
                                                          "csvTableData." +
                                                          csvItemIndex +
                                                          ".budomari",
                                                        rules: [
                                                          {
                                                            required: false,
                                                            trigger: "blur",
                                                          },
                                                          {
                                                            validator:
                                                              _vm.checkBudomari1,
                                                            trigger: "blur",
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-input",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "messages.yieldRate"
                                                              ),
                                                            size: "small",
                                                          },
                                                          on: {
                                                            input: function (
                                                              value
                                                            ) {
                                                              return (csvItem.budomari =
                                                                value
                                                                  .replace(
                                                                    /\D*(\d*)(\.?)(\d*)\d*/,
                                                                    "$1$2$3"
                                                                  )
                                                                  .match(
                                                                    /^\d*(\.?\d*)/g
                                                                  )[0] || "")
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              csvItem.budomari,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                csvItem,
                                                                "budomari",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "csvItem.budomari",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticStyle: {
                                                                "font-style":
                                                                  "normal",
                                                                "margin-right":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                slot: "suffix",
                                                              },
                                                              slot: "suffix",
                                                            },
                                                            [_vm._v("%")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "el-col",
                                                {
                                                  staticClass: "table-bg-white",
                                                  staticStyle: {
                                                    "border-bottom":
                                                      "1px solid #EBEEF5",
                                                    width: "8%",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      key: csvItemIndex,
                                                      attrs: {
                                                        prop:
                                                          "csvTableData." +
                                                          csvItemIndex +
                                                          ".budomari",
                                                        rules: [
                                                          {
                                                            required: false,
                                                            trigger: "blur",
                                                          },
                                                          {
                                                            validator:
                                                              _vm.checkBudomari1,
                                                            trigger: "blur",
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-input",
                                                        {
                                                          staticStyle: {
                                                            visibility:
                                                              "hidden",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "messages.yieldRate"
                                                              ),
                                                            size: "small",
                                                          },
                                                          on: {
                                                            input: function (
                                                              value
                                                            ) {
                                                              return (csvItem.budomari =
                                                                value
                                                                  .replace(
                                                                    /\D*(\d*)(\.?)(\d*)\d*/,
                                                                    "$1$2$3"
                                                                  )
                                                                  .match(
                                                                    /^\d*(\.?\d*)/g
                                                                  )[0] || "")
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              csvItem.budomari,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                csvItem,
                                                                "budomari",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "csvItem.budomari",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticStyle: {
                                                                "font-style":
                                                                  "normal",
                                                                "margin-right":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                slot: "suffix",
                                                              },
                                                              slot: "suffix",
                                                            },
                                                            [_vm._v("%")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass:
                                                "table-col col-width",
                                              staticStyle: {
                                                cursor: "auto",
                                                width: "5%",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "item-memo-div-popup",
                                                },
                                                [
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      attrs: {
                                                        placement: "right",
                                                        disabled:
                                                          _vm.statusDisabled,
                                                      },
                                                    },
                                                    [
                                                      _c("textarea", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              csvItem.itemmemo,
                                                            expression:
                                                              "csvItem.itemmemo",
                                                          },
                                                        ],
                                                        attrs: {
                                                          cols: "40",
                                                          rows: "10",
                                                          maxlength: "3000",
                                                          placeholder:
                                                            _vm.$t(
                                                              "messages.note"
                                                            ),
                                                        },
                                                        domProps: {
                                                          value:
                                                            csvItem.itemmemo,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              csvItem,
                                                              "itemmemo",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "item-memo-popup-size",
                                                          style: {
                                                            cursor:
                                                              _vm.statusDisabled
                                                                ? "not-allowed"
                                                                : "pointer",
                                                          },
                                                          attrs: {
                                                            slot: "reference",
                                                          },
                                                          slot: "reference",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/static/icon/item-memo.svg"),
                                                              width: "16",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                    attrs: { title: "Delete" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.rowToDelete(
                                                          csvItemIndex
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.saveCsvDisable,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toRuleDialog("csvItem")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("messages.register")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.parentFlag !== 2 && !_vm.noTreeFromCsvOrApi
                ? _c(
                    "div",
                    [
                      _vm._l(
                        _vm.processList,
                        function (
                          subProcess,
                          subProcessIndex,
                          processListIndex
                        ) {
                          return _c(
                            "div",
                            {
                              key: subProcessIndex,
                              staticClass: "process-item",
                              attrs: { id: _vm.setId(subProcessIndex) },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "process-icon",
                                  attrs: { id: subProcessIndex },
                                },
                                [
                                  _c("img", {
                                    staticClass: "delete",
                                    attrs: {
                                      src: require("@/assets/static/icon/icon24_delete.svg"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteProcess(
                                          subProcessIndex
                                        )
                                      },
                                    },
                                  }),
                                  subProcess && subProcess[0].icon
                                    ? _c("img", {
                                        attrs: {
                                          src: _vm.getIconUrl(subProcess),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.buttonFlag === 2
                                    ? _c("div", { staticClass: "icon-font" }, [
                                        _vm._v(
                                          _vm._s(subProcess[0].processcode) +
                                            ": " +
                                            _vm._s(subProcess[0].processname)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.buttonFlag === 1
                                    ? _c("div", { staticClass: "icon-font" }, [
                                        _vm._v(
                                          _vm._s(subProcess[0].processcode) +
                                            ": " +
                                            _vm._s(
                                              _vm.$t(
                                                "messages." +
                                                  subProcess[0].processcode
                                              )
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "process-body" },
                                [
                                  _vm._l(
                                    subProcess,
                                    function (
                                      subProcessItem,
                                      subProcessItemIndex
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: subProcessItemIndex,
                                          staticClass: "process-input",
                                        },
                                        [
                                          _c(
                                            "el-form",
                                            {
                                              ref: "subProcessItem",
                                              refInFor: true,
                                              staticClass: "subProcessForm",
                                              attrs: {
                                                model: subProcessItem,
                                                rules: _vm.rules,
                                                "validate-on-rule-change": false,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "komoku-div" },
                                                [
                                                  subProcessItemIndex !== 0
                                                    ? _c("div", {
                                                        staticClass:
                                                          "blank-div",
                                                      })
                                                    : _vm._e(),
                                                  subProcessItemIndex === 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.itemName"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          processListIndex === 0
                                                            ? _c(
                                                                "el-popover",
                                                                {
                                                                  staticClass:
                                                                    "suggestion-icon",
                                                                  attrs: {
                                                                    placement:
                                                                      "right-start",
                                                                    width:
                                                                      "200",
                                                                    trigger:
                                                                      "hover",
                                                                    content:
                                                                      _vm.$t(
                                                                        "messages.itemContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-question",
                                                                    attrs: {
                                                                      slot: "reference",
                                                                    },
                                                                    slot: "reference",
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop: "itemname",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticClass:
                                                          "komoku-input",
                                                        attrs: {
                                                          placeholder:
                                                            _vm.$t(
                                                              "messages.itemName"
                                                            ),
                                                          size: "small",
                                                          disabled:
                                                            _vm.statusDisabled,
                                                        },
                                                        model: {
                                                          value:
                                                            subProcessItem.itemname,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              subProcessItem,
                                                              "itemname",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "subProcessItem.itemname",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "surmise-process",
                                                      class:
                                                        subProcessItem.itemname
                                                          ? "surmise-process-enable"
                                                          : "surmise-process-disable",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.surmiseIdeaName(
                                                            subProcessItem.itemname,
                                                            subProcessIndex,
                                                            subProcessItemIndex
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "messages.EFAnalogy"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "genntani-div" },
                                                [
                                                  subProcessItemIndex !== 0
                                                    ? _c("div", {
                                                        staticClass:
                                                          "blank-div",
                                                      })
                                                    : _vm._e(),
                                                  subProcessItemIndex === 0 &&
                                                  subProcess[0].processcode !==
                                                    "Z"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.efName"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          processListIndex === 0
                                                            ? _c(
                                                                "el-popover",
                                                                {
                                                                  staticClass:
                                                                    "suggestion-icon",
                                                                  attrs: {
                                                                    placement:
                                                                      "right-start",
                                                                    width:
                                                                      "200",
                                                                    trigger:
                                                                      "hover",
                                                                    content:
                                                                      _vm.$t(
                                                                        "messages.efContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-question",
                                                                    attrs: {
                                                                      slot: "reference",
                                                                    },
                                                                    slot: "reference",
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  subProcess[0].processcode !==
                                                  "Z"
                                                    ? _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "name",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-autocomplete",
                                                            {
                                                              staticClass:
                                                                "genntani-input",
                                                              attrs: {
                                                                size: "small",
                                                                "fetch-suggestions":
                                                                  _vm.querySearch,
                                                                placeholder:
                                                                  _vm.$t(
                                                                    "messages.peefn"
                                                                  ),
                                                                "trigger-on-focus": false,
                                                                disabled:
                                                                  _vm.statusDisabled,
                                                              },
                                                              on: {
                                                                select:
                                                                  function (
                                                                    item
                                                                  ) {
                                                                    _vm.handleSelect(
                                                                      item,
                                                                      subProcessIndex,
                                                                      subProcessItemIndex
                                                                    )
                                                                  },
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputChange(
                                                                      subProcessItem.displayname,
                                                                      subProcessIndex,
                                                                      subProcessItemIndex
                                                                    )
                                                                  },
                                                                focus:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.focusChange(
                                                                      subProcessIndex,
                                                                      subProcessItemIndex
                                                                    )
                                                                  },
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "default",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var item =
                                                                          ref.item
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.displayname
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                              model: {
                                                                value:
                                                                  subProcessItem.displayname,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      subProcessItem,
                                                                      "displayname",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "subProcessItem.displayname",
                                                              },
                                                            }
                                                          ),
                                                          subProcessItem.isDeleted
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#F56C6C",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "messages.EFDelete"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !subProcessItem.isDeleted &&
                                                          subProcessItem.generationBoolean
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "orange",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "messages.EFChanged"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !subProcessItem.isDeleted &&
                                                          !subProcessItem.generationBoolean &&
                                                          subProcessItem.unitDeleted
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#F56C6C",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "messages.subunitDelete"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          !subProcessItem.isDeleted &&
                                                          !subProcessItem.generationBoolean &&
                                                          !subProcessItem.unitDeleted &&
                                                          subProcessItem.unitgenerationBoolean
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "orange",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "messages.subunitChanged"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "katsudo-div" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        subProcessItemIndex ===
                                                        0
                                                          ? "katsudo-hasLabel-div"
                                                          : "katsudo-noLabel-div",
                                                    },
                                                    [
                                                      subProcessItemIndex === 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-title",
                                                            },
                                                            [
                                                              subProcess[0]
                                                                .processcode !==
                                                              "Z"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "messages.amount"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              subProcess[0]
                                                                .processcode ===
                                                              "Z"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "messages.emission"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              processListIndex ===
                                                              0
                                                                ? _c(
                                                                    "el-popover",
                                                                    {
                                                                      staticClass:
                                                                        "suggestion-icon",
                                                                      attrs: {
                                                                        placement:
                                                                          "right-start",
                                                                        width:
                                                                          "200",
                                                                        trigger:
                                                                          "hover",
                                                                        content:
                                                                          _vm.$t(
                                                                            "messages.amountContent"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-question",
                                                                        attrs: {
                                                                          slot: "reference",
                                                                        },
                                                                        slot: "reference",
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  subProcessItemIndex !== 0
                                                    ? _c("div", {
                                                        staticClass:
                                                          "blank-div",
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop: "quantity",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticClass:
                                                          "katsudo-input",
                                                        attrs: {
                                                          disabled:
                                                            _vm.statusDisabled,
                                                          size: "small",
                                                          placeholder:
                                                            subProcess[0]
                                                              .processcode ===
                                                            "Z"
                                                              ? _vm.$t(
                                                                  "messages.emission"
                                                                )
                                                              : _vm.$t(
                                                                  "messages.amount"
                                                                ),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.test1(
                                                              subProcessItem
                                                            )
                                                          },
                                                          input: function (
                                                            value
                                                          ) {
                                                            return (subProcessItem.quantity =
                                                              value
                                                                .replace(
                                                                  /(\-?)\D*(\d*)(\.?)(\d*)\d*/,
                                                                  "$1$2$3$4"
                                                                )
                                                                .match(
                                                                  /^\-?\d*(\.?\d*)/g
                                                                )[0] || "")
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            subProcessItem.quantity,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              subProcessItem,
                                                              "quantity",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "subProcessItem.quantity",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  subProcess[0].processcode ===
                                                  "A1"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "surmise-process",
                                                          class:
                                                            subProcessItem.quantity &&
                                                            subProcessItem.displayname
                                                              ? "surmise-process-enable"
                                                              : "surmise-process-disable",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.trafficCalculator(
                                                                subProcessItem.itemname,
                                                                subProcessItem.quantity,
                                                                subProcessItem.unit,
                                                                subProcessItem.unitList,
                                                                subProcessItem.displayname,
                                                                0
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "messages.A2Calculate"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "tanni-div" },
                                                [
                                                  subProcessItemIndex !== 0
                                                    ? _c("div", {
                                                        staticClass:
                                                          "blank-div",
                                                      })
                                                    : _vm._e(),
                                                  subProcessItemIndex === 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.unit"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          processListIndex === 0
                                                            ? _c(
                                                                "el-popover",
                                                                {
                                                                  staticClass:
                                                                    "suggestion-icon",
                                                                  attrs: {
                                                                    placement:
                                                                      "right-start",
                                                                    width:
                                                                      "200",
                                                                    trigger:
                                                                      "hover",
                                                                    content:
                                                                      _vm.$t(
                                                                        "messages.unitContent"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-question",
                                                                    attrs: {
                                                                      slot: "reference",
                                                                    },
                                                                    slot: "reference",
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-form-item",
                                                    { attrs: { prop: "unit" } },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              _vm.$t(
                                                                "messages.unit"
                                                              ),
                                                            size: "small",
                                                            disabled:
                                                              _vm.statusDisabled,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.unitChange(
                                                                subProcessItem.unit,
                                                                subProcessItem.unitList,
                                                                subProcessIndex,
                                                                subProcessItemIndex
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              subProcessItem.unit,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                subProcessItem,
                                                                "unit",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "subProcessItem.unit",
                                                          },
                                                        },
                                                        _vm._l(
                                                          subProcessItem.unitList,
                                                          function (item, i) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: i,
                                                                attrs: {
                                                                  value:
                                                                    item.percent +
                                                                    "/unitlabel/" +
                                                                    item.tounit,
                                                                  label:
                                                                    item.tounit,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "katsudo-div" },
                                                [
                                                  subProcessItemIndex !== 0
                                                    ? _c("div", {
                                                        staticClass:
                                                          "blank-div",
                                                      })
                                                    : _vm._e(),
                                                  subProcessItemIndex === 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.intermediateProduct"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop: "interimproduct",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticClass:
                                                          "katsudo-input",
                                                        attrs: {
                                                          disabled:
                                                            _vm.statusDisabled,
                                                          size: "small",
                                                          maxlength: "40",
                                                        },
                                                        model: {
                                                          value:
                                                            subProcessItem.interimproduct,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              subProcessItem,
                                                              "interimproduct",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "subProcessItem.interimproduct",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "rate-div" },
                                                [
                                                  subProcessItemIndex !== 0 &&
                                                  (subProcess[0].processcode ===
                                                    "A1" ||
                                                    subProcess[0]
                                                      .processcode === "A2" ||
                                                    subProcess[0]
                                                      .processcode === "A3" ||
                                                    subProcess[0].genProcess ===
                                                      "A1" ||
                                                    subProcess[0].genProcess ===
                                                      "A2" ||
                                                    subProcess[0].genProcess ===
                                                      "A3")
                                                    ? _c("div", {
                                                        staticClass:
                                                          "blank-div",
                                                      })
                                                    : _vm._e(),
                                                  subProcessItemIndex === 0 &&
                                                  (subProcess[0].processcode ===
                                                    "A1" ||
                                                    subProcess[0]
                                                      .processcode === "A2" ||
                                                    subProcess[0]
                                                      .processcode === "A3" ||
                                                    subProcess[0].genProcess ===
                                                      "A1" ||
                                                    subProcess[0].genProcess ===
                                                      "A2" ||
                                                    subProcess[0].genProcess ===
                                                      "A3")
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-title",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.yieldRate"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop: "budomari",
                                                      },
                                                    },
                                                    [
                                                      subProcess[0]
                                                        .processcode === "A1" ||
                                                      subProcess[0]
                                                        .processcode === "A2" ||
                                                      subProcess[0]
                                                        .processcode === "A3" ||
                                                      subProcess[0]
                                                        .genProcess === "A1" ||
                                                      subProcess[0]
                                                        .genProcess === "A2" ||
                                                      subProcess[0]
                                                        .genProcess === "A3"
                                                        ? _c(
                                                            "el-input",
                                                            {
                                                              staticClass:
                                                                "katsudo-input",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.statusDisabled,
                                                                size: "small",
                                                                maxlength: "40",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.test1(
                                                                      subProcessItem,
                                                                      subProcessItemIndex
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    value
                                                                  ) {
                                                                    return (subProcessItem.budomari =
                                                                      value
                                                                        .replace(
                                                                          /\D*(\d*)(\.?)(\d*)\d*/,
                                                                          "$1$2$3"
                                                                        )
                                                                        .match(
                                                                          /^\d*(\.?\d*)/g
                                                                        )[0] ||
                                                                      "")
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  subProcessItem.budomari,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      subProcessItem,
                                                                      "budomari",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "subProcessItem.budomari",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticStyle: {
                                                                    "font-style":
                                                                      "normal",
                                                                    "margin-right":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    slot: "suffix",
                                                                  },
                                                                  slot: "suffix",
                                                                },
                                                                [_vm._v("%")]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "emission-div" },
                                                [
                                                  subProcessItemIndex !== 0
                                                    ? _c("div", {
                                                        staticClass:
                                                          "blank-div",
                                                        staticStyle: {
                                                          width: "13rem",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  subProcessItemIndex === 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "item-title",
                                                          staticStyle: {
                                                            width: "12rem",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.emissionc"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop: "emissionc",
                                                      },
                                                    },
                                                    [
                                                      subProcessItem.coefficient &&
                                                      !subProcessItem.isDeleted &&
                                                      !subProcessItem.unitDeleted &&
                                                      subProcessItem.budomari &&
                                                      subProcessItem.budomari !==
                                                        ""
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "#E7E6E6",
                                                                display:
                                                                  "block",
                                                                "padding-left":
                                                                  "1rem",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.toScientificNum(
                                                                    subProcessItem.quantity *
                                                                      (subProcessItem.coefficient /
                                                                        (parseFloat(
                                                                          subProcessItem.budomari ===
                                                                            "0"
                                                                            ? ""
                                                                            : subProcessItem.budomari
                                                                        ) /
                                                                          100)) *
                                                                      (isNaN(
                                                                        Number(
                                                                          subProcessItem.transferUnitPercent
                                                                        )
                                                                      )
                                                                        ? 1
                                                                        : Number(
                                                                            subProcessItem.transferUnitPercent
                                                                          ))
                                                                  )[0]
                                                                ) +
                                                                  _vm._s(
                                                                    _vm.toScientificNum(
                                                                      subProcessItem.quantity *
                                                                        (subProcessItem.coefficient /
                                                                          (parseFloat(
                                                                            subProcessItem.budomari ===
                                                                              "0"
                                                                              ? ""
                                                                              : subProcessItem.budomari
                                                                          ) /
                                                                            100)) *
                                                                        (isNaN(
                                                                          Number(
                                                                            subProcessItem.transferUnitPercent
                                                                          )
                                                                        )
                                                                          ? 1
                                                                          : Number(
                                                                              subProcessItem.transferUnitPercent
                                                                            ))
                                                                    )[1]
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("sup", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.toScientificNum(
                                                                        subProcessItem.quantity *
                                                                          (subProcessItem.coefficient /
                                                                            (parseFloat(
                                                                              subProcessItem.budomari ===
                                                                                "0"
                                                                                ? ""
                                                                                : subProcessItem.budomari
                                                                            ) /
                                                                              100)) *
                                                                          (isNaN(
                                                                            Number(
                                                                              subProcessItem.transferUnitPercent
                                                                            )
                                                                          )
                                                                            ? 1
                                                                            : Number(
                                                                                subProcessItem.transferUnitPercent
                                                                              ))
                                                                      )[2]
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : subProcessItem.coefficient &&
                                                          !subProcessItem.isDeleted &&
                                                          !subProcessItem.unitDeleted &&
                                                          !subProcessItem.budomari
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "#E7E6E6",
                                                                display:
                                                                  "block",
                                                                "padding-left":
                                                                  "1rem",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.toScientificNum(
                                                                    subProcessItem.quantity *
                                                                      subProcessItem.coefficient *
                                                                      (isNaN(
                                                                        Number(
                                                                          subProcessItem.transferUnitPercent
                                                                        )
                                                                      )
                                                                        ? 1
                                                                        : Number(
                                                                            subProcessItem.transferUnitPercent
                                                                          ))
                                                                  )[0]
                                                                ) +
                                                                  _vm._s(
                                                                    _vm.toScientificNum(
                                                                      subProcessItem.quantity *
                                                                        subProcessItem.coefficient *
                                                                        (isNaN(
                                                                          Number(
                                                                            subProcessItem.transferUnitPercent
                                                                          )
                                                                        )
                                                                          ? 1
                                                                          : Number(
                                                                              subProcessItem.transferUnitPercent
                                                                            ))
                                                                    )[1]
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("sup", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.toScientificNum(
                                                                        subProcessItem.quantity *
                                                                          subProcessItem.coefficient *
                                                                          (isNaN(
                                                                            Number(
                                                                              subProcessItem.transferUnitPercent
                                                                            )
                                                                          )
                                                                            ? 1
                                                                            : Number(
                                                                                subProcessItem.transferUnitPercent
                                                                              ))
                                                                      )[2]
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : !subProcessItem.coefficient ||
                                                          subProcessItem.isDeleted ||
                                                          subProcessItem.unitDeleted
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "#E7E6E6",
                                                                display:
                                                                  "block",
                                                                "padding-left":
                                                                  "1rem",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.toScientificNum(
                                                                    subProcessItem.emissionc
                                                                  )[0]
                                                                ) +
                                                                  _vm._s(
                                                                    _vm.toScientificNum(
                                                                      subProcessItem.emissionc
                                                                    )[1]
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("sup", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.toScientificNum(
                                                                        subProcessItem.emissionc
                                                                      )[2]
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "item-memo-div",
                                                },
                                                [
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      attrs: {
                                                        placement: _vm.right,
                                                        disabled:
                                                          _vm.statusDisabled,
                                                      },
                                                    },
                                                    [
                                                      _c("textarea", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              subProcessItem.itemmemo,
                                                            expression:
                                                              "subProcessItem.itemmemo",
                                                          },
                                                        ],
                                                        attrs: {
                                                          cols: "40",
                                                          rows: "10",
                                                          maxlength: "3000",
                                                          placeholder:
                                                            _vm.$t(
                                                              "messages.note"
                                                            ),
                                                        },
                                                        domProps: {
                                                          value:
                                                            subProcessItem.itemmemo,
                                                        },
                                                        on: {
                                                          input: [
                                                            function ($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                subProcessItem,
                                                                "itemmemo",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.$forceUpdate()
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      subProcessItemIndex === 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "item-memo-size",
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "0.7rem",
                                                              },
                                                              attrs: {
                                                                slot: "reference",
                                                              },
                                                              slot: "reference",
                                                            },
                                                            [
                                                              _c("img", {
                                                                style: {
                                                                  cursor:
                                                                    _vm.statusDisabled
                                                                      ? "not-allowed"
                                                                      : "pointer",
                                                                },
                                                                attrs: {
                                                                  src: require("@/assets/static/icon/item-memo.svg"),
                                                                  width: "16",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      subProcessItemIndex !== 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "item-memo-size",
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "2rem",
                                                              },
                                                              attrs: {
                                                                slot: "reference",
                                                              },
                                                              slot: "reference",
                                                            },
                                                            [
                                                              _c("img", {
                                                                style: {
                                                                  cursor:
                                                                    _vm.statusDisabled
                                                                      ? "not-allowed"
                                                                      : "pointer",
                                                                },
                                                                attrs: {
                                                                  src: require("@/assets/static/icon/item-memo.svg"),
                                                                  width: "16",
                                                                },
                                                              }),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "delete-icon-div",
                                                },
                                                [
                                                  subProcessItemIndex === 0
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-delete",
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "0.7rem",
                                                        },
                                                        style: {
                                                          cursor:
                                                            _vm.statusDisabled
                                                              ? "not-allowed"
                                                              : "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteSubProcess(
                                                              subProcessIndex,
                                                              subProcessItemIndex
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  subProcessItemIndex !== 0
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-delete",
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "2rem",
                                                        },
                                                        style: {
                                                          cursor:
                                                            _vm.statusDisabled
                                                              ? "not-allowed"
                                                              : "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteSubProcess(
                                                              subProcessIndex,
                                                              subProcessItemIndex
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c("div", { staticClass: "item-button" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "new-item-button",
                                        style: {
                                          cursor: _vm.statusDisabled
                                            ? "not-allowed"
                                            : "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addProcessSub(
                                              subProcessIndex,
                                              subProcess
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("messages.addNewItem")
                                          ) + " +"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          )
                        }
                      ),
                      _vm.parentFlag !== 2
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn-color new-process-button",
                              attrs: {
                                disabled: _vm.statusDisabled,
                                type: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addProcess(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("messages.addNewProcess")) + " +"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _vm.parentFlag === 2 || _vm.noTreeFromCsvOrApi || _vm.copyFlag
              ? _c(
                  "div",
                  { attrs: { id: "treeForm" } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "treeDataForm",
                        staticClass: "subProcessForm-parentmode",
                        attrs: { model: _vm.treeDataForm },
                      },
                      _vm._l(
                        _vm.treeDataForm.treeDataItem,
                        function (treeDataItem, index) {
                          return _c(
                            "div",
                            {
                              key: treeDataItem.key,
                              staticClass: "process-input",
                              staticStyle: { width: "100%" },
                              attrs: { id: "treeData" },
                            },
                            [
                              !treeDataItem.subassyFromId
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "process-body",
                                      staticStyle: {
                                        width: "100%",
                                        display: "flex",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "komoku-div-parentmode",
                                          style: {
                                            width: _vm.noTreeFromCsvOrApi
                                              ? "15%"
                                              : "12%",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "messages.managedItem"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              index === 0
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      staticClass:
                                                        "suggestion-icon",
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        width: "200",
                                                        trigger: "hover",
                                                        content: _vm.$t(
                                                          "messages.itemContent"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".itemname",
                                                rules: _vm.newRules.itemname,
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "komoku-input",
                                                attrs: {
                                                  placeholder:
                                                    _vm.$t("messages.itemName"),
                                                  size: "small",
                                                  disabled: _vm.statusDisabled,
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.itemNameFocus(
                                                      treeDataItem.itemname,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: treeDataItem.itemname,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      treeDataItem,
                                                      "itemname",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "treeDataItem.itemname",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "surmise-process",
                                              class:
                                                treeDataItem.itemname !== ""
                                                  ? "surmise-process-enable"
                                                  : "surmise-process-disable",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.surmiseTreeIdeaName(
                                                    treeDataItem.itemname,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("messages.EFAnalogy")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "komoku-div-parentmode",
                                          style: {
                                            width: _vm.noTreeFromCsvOrApi
                                              ? "15%"
                                              : "12%",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.process")
                                                  ) +
                                                  " "
                                              ),
                                              index === 0
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      staticClass:
                                                        "suggestion-icon",
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        trigger: "hover",
                                                        width: "200",
                                                        content: _vm.$t(
                                                          "messages.processContent"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".processcode",
                                                rules: _vm.newRules.processcode,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "select-item",
                                                  attrs: {
                                                    size: "small",
                                                    filterable: "",
                                                    disabled:
                                                      _vm.statusDisabled,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.addtreeDataGenprocess(
                                                        treeDataItem,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      treeDataItem.processcode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        treeDataItem,
                                                        "processcode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "treeDataItem.processcode",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.processnameList,
                                                  function (item, i) {
                                                    return _c("el-option", {
                                                      key: item.processCode + i,
                                                      attrs: {
                                                        label:
                                                          _vm.language ===
                                                            "en" &&
                                                          _vm.buttonFlag == 1 &&
                                                          _vm.parentFlag == 2
                                                            ? _vm.$t(
                                                                "messages." +
                                                                  item.processCode
                                                              ) +
                                                              "(" +
                                                              item.processCode +
                                                              ")"
                                                            : item.processName +
                                                              "(" +
                                                              item.processCode +
                                                              ")",
                                                        value: item.processCode,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "genntani-div-parentmode",
                                          style: {
                                            width: _vm.noTreeFromCsvOrApi
                                              ? "15%"
                                              : "12%",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.efName")
                                                  ) +
                                                  " "
                                              ),
                                              index === 0
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      staticClass:
                                                        "suggestion-icon",
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        width: "200",
                                                        trigger: "hover",
                                                        content:
                                                          _vm.$t(
                                                            "messages.efContent"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          treeDataItem.processcode !== "Z"
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    prop:
                                                      "treeDataItem." +
                                                      index +
                                                      ".displayname",
                                                    rules:
                                                      _vm.newRules.displayname,
                                                  },
                                                },
                                                [
                                                  _c("el-autocomplete", {
                                                    staticClass:
                                                      "genntani-input",
                                                    attrs: {
                                                      size: "small",
                                                      "fetch-suggestions":
                                                        _vm.querySearch,
                                                      placeholder:
                                                        _vm.$t(
                                                          "messages.peefn"
                                                        ),
                                                      "trigger-on-focus": false,
                                                      disabled:
                                                        _vm.statusDisabled,
                                                    },
                                                    on: {
                                                      select: function (item) {
                                                        _vm.newhandleSelect(
                                                          item,
                                                          index
                                                        )
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.newInputChange(
                                                          treeDataItem.displayname,
                                                          index
                                                        )
                                                      },
                                                      focus: function ($event) {
                                                        return _vm.newFocusChange(
                                                          index
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var item = ref.item
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.displayname
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        treeDataItem.displayname,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          treeDataItem,
                                                          "displayname",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "treeDataItem.displayname",
                                                    },
                                                  }),
                                                  treeDataItem.isDeleted
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#F56C6C",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.EFDelete"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !treeDataItem.isDeleted &&
                                                  treeDataItem.generationBoolean
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "orange",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.EFChanged"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !treeDataItem.isDeleted &&
                                                  !treeDataItem.generationBoolean &&
                                                  treeDataItem.unitDeleted
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#F56C6C",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.subunitDelete"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !treeDataItem.isDeleted &&
                                                  !treeDataItem.generationBoolean &&
                                                  !treeDataItem.unitDeleted &&
                                                  treeDataItem.unitgenerationBoolean
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "orange",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.subunitChanged"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "katsudo-div-parentmode",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "katsudo-hasLabel-div",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "item-title" },
                                                [
                                                  treeDataItem.processcode !==
                                                  "Z"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "messages.amount"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  treeDataItem.processcode ===
                                                  "Z"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "messages.emission"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  index === 0
                                                    ? _c(
                                                        "el-popover",
                                                        {
                                                          staticClass:
                                                            "suggestion-icon",
                                                          attrs: {
                                                            placement:
                                                              "right-start",
                                                            width: "200",
                                                            trigger: "hover",
                                                            content: _vm.$t(
                                                              "messages.amountContent"
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-question",
                                                            attrs: {
                                                              slot: "reference",
                                                            },
                                                            slot: "reference",
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".quantity",
                                                rules: _vm.newRules.quantity,
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "katsudo-input",
                                                attrs: {
                                                  disabled: _vm.statusDisabled,
                                                  size: "small",
                                                  placeholder:
                                                    treeDataItem.processcode ===
                                                    "Z"
                                                      ? _vm.$t(
                                                          "messages.emission"
                                                        )
                                                      : _vm.$t(
                                                          "messages.amount"
                                                        ),
                                                  title: "",
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.test2(
                                                      treeDataItem
                                                    )
                                                  },
                                                  input: function (value) {
                                                    return (treeDataItem.quantity =
                                                      value
                                                        .replace(
                                                          /(\-?)\D*(\d*)(\.?)(\d*)\d*/,
                                                          "$1$2$3$4"
                                                        )
                                                        .match(
                                                          /^\-?\d*(\.?\d*)/g
                                                        )[0] || "")
                                                  },
                                                },
                                                model: {
                                                  value: treeDataItem.quantity,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      treeDataItem,
                                                      "quantity",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "treeDataItem.quantity",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          treeDataItem.processcode === "A1"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "surmise-process",
                                                  class:
                                                    treeDataItem.quantity &&
                                                    treeDataItem.displayname
                                                      ? "surmise-process-enable"
                                                      : "surmise-process-disable",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.trafficCalculator(
                                                        treeDataItem.itemname,
                                                        treeDataItem.quantity,
                                                        treeDataItem.unit,
                                                        treeDataItem.unitList,
                                                        treeDataItem.displayname,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "messages.A2Calculate"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "tanni-div-parentmode" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.unit")
                                                  ) +
                                                  " "
                                              ),
                                              index === 0
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      staticClass:
                                                        "suggestion-icon",
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        width: "200",
                                                        trigger: "hover",
                                                        content: _vm.$t(
                                                          "messages.unitContent"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "unit" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder:
                                                      _vm.$t("messages.unit"),
                                                    size: "small",
                                                    disabled:
                                                      _vm.statusDisabled,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.unittreeChange(
                                                        treeDataItem.unit,
                                                        treeDataItem.unitList,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: treeDataItem.unit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        treeDataItem,
                                                        "unit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "treeDataItem.unit",
                                                  },
                                                },
                                                _vm._l(
                                                  treeDataItem.unitList,
                                                  function (item, i) {
                                                    return _c("el-option", {
                                                      key: i,
                                                      attrs: {
                                                        value:
                                                          item.percent +
                                                          "/unitlabel/" +
                                                          item.tounit,
                                                        label: item.tounit,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "katsudo-div-parentmode",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "messages.intermediateProduct"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".interimproduct",
                                                rules:
                                                  _vm.newRules.interimproduct,
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "katsudo-input",
                                                attrs: {
                                                  disabled: _vm.statusDisabled,
                                                  size: "small",
                                                  maxlength: "40",
                                                },
                                                model: {
                                                  value:
                                                    treeDataItem.interimproduct,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      treeDataItem,
                                                      "interimproduct",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "treeDataItem.interimproduct",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.noTreeFromCsvOrApi
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "katsudo-div-parentmode",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "item-title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "messages.parentItem"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    prop:
                                                      "treeDataItem." +
                                                      index +
                                                      ".parentnode",
                                                    rules:
                                                      _vm.newRules.parentnode,
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass:
                                                      "katsudo-input",
                                                    attrs: {
                                                      disabled:
                                                        _vm.statusDisabled ||
                                                        !treeDataItem.itemname,
                                                      size: "small",
                                                      maxlength: "99",
                                                    },
                                                    on: {
                                                      blur: function ($event) {
                                                        return _vm.changeTempArr(
                                                          treeDataItem
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        treeDataItem.parentnode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          treeDataItem,
                                                          "parentnode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "treeDataItem.parentnode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "rate-div-parentmode" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.yieldRate")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".budomari",
                                                rules: _vm.newRules.budomari,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass: "katsudo-input",
                                                  attrs: {
                                                    disabled:
                                                      _vm.statusDisabled ||
                                                      !(
                                                        treeDataItem.processcode ===
                                                          "A1" ||
                                                        treeDataItem.processcode ===
                                                          "A2" ||
                                                        treeDataItem.processcode ===
                                                          "A3" ||
                                                        treeDataItem.genProcess ===
                                                          "A1" ||
                                                        treeDataItem.genProcess ===
                                                          "A2" ||
                                                        treeDataItem.genProcess ===
                                                          "A3"
                                                      ),
                                                    size: "small",
                                                    maxlength: "40",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.test2(
                                                        treeDataItem
                                                      )
                                                    },
                                                    input: function (value) {
                                                      return (treeDataItem.budomari =
                                                        value
                                                          .replace(
                                                            /\D*(\d*)(\.?)(\d*)\d*/,
                                                            "$1$2$3"
                                                          )
                                                          .match(
                                                            /^\d*(\.?\d*)/g
                                                          )[0] || "")
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      treeDataItem.budomari,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        treeDataItem,
                                                        "budomari",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "treeDataItem.budomari",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        "font-style": "normal",
                                                        "margin-right": "5px",
                                                      },
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [_vm._v("%")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "emission-div-parentmode",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.emissionc")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "emissionc" } },
                                            [
                                              treeDataItem.coefficient &&
                                              treeDataItem.budomari &&
                                              treeDataItem.budomari != "" &&
                                              !treeDataItem.isDeleted &&
                                              !treeDataItem.unitDeleted
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#E7E6E6",
                                                        display: "block",
                                                        "padding-left": "1rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.toScientificNum(
                                                            treeDataItem.quantity *
                                                              (treeDataItem.coefficient /
                                                                (parseFloat(
                                                                  treeDataItem.budomari ===
                                                                    "0"
                                                                    ? ""
                                                                    : treeDataItem.budomari
                                                                ) /
                                                                  100)) *
                                                              (isNaN(
                                                                Number(
                                                                  treeDataItem.transferUnitPercent
                                                                )
                                                              )
                                                                ? 1
                                                                : Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  ))
                                                          )[0]
                                                        ) +
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.quantity *
                                                                (treeDataItem.coefficient /
                                                                  (parseFloat(
                                                                    treeDataItem.budomari ===
                                                                      "0"
                                                                      ? ""
                                                                      : treeDataItem.budomari
                                                                  ) /
                                                                    100)) *
                                                                (isNaN(
                                                                  Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  )
                                                                )
                                                                  ? 1
                                                                  : Number(
                                                                      treeDataItem.transferUnitPercent
                                                                    ))
                                                            )[1]
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("sup", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.quantity *
                                                                (treeDataItem.coefficient /
                                                                  (parseFloat(
                                                                    treeDataItem.budomari ===
                                                                      "0"
                                                                      ? ""
                                                                      : treeDataItem.budomari
                                                                  ) /
                                                                    100)) *
                                                                (isNaN(
                                                                  Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  )
                                                                )
                                                                  ? 1
                                                                  : Number(
                                                                      treeDataItem.transferUnitPercent
                                                                    ))
                                                            )[2]
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : treeDataItem.coefficient &&
                                                  !treeDataItem.isDeleted &&
                                                  !treeDataItem.unitDeleted &&
                                                  !treeDataItem.budomari
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#E7E6E6",
                                                        display: "block",
                                                        "padding-left": "1rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.toScientificNum(
                                                            treeDataItem.quantity *
                                                              treeDataItem.coefficient *
                                                              (isNaN(
                                                                Number(
                                                                  treeDataItem.transferUnitPercent
                                                                )
                                                              )
                                                                ? 1
                                                                : Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  ))
                                                          )[0]
                                                        ) +
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.quantity *
                                                                treeDataItem.coefficient *
                                                                (isNaN(
                                                                  Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  )
                                                                )
                                                                  ? 1
                                                                  : Number(
                                                                      treeDataItem.transferUnitPercent
                                                                    ))
                                                            )[1]
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("sup", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.quantity *
                                                                treeDataItem.coefficient *
                                                                (isNaN(
                                                                  Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  )
                                                                )
                                                                  ? 1
                                                                  : Number(
                                                                      treeDataItem.transferUnitPercent
                                                                    ))
                                                            )[2]
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : !treeDataItem.coefficient ||
                                                  treeDataItem.isDeleted ||
                                                  treeDataItem.unitDeleted
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#E7E6E6",
                                                        display: "block",
                                                        "padding-left": "1rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.toScientificNum(
                                                            treeDataItem.emissionc
                                                          )[0]
                                                        ) +
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.emissionc
                                                            )[1]
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("sup", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.emissionc
                                                            )[2]
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item-memo-div-parentmode",
                                        },
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "right",
                                                disabled: _vm.statusDisabled,
                                              },
                                            },
                                            [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      treeDataItem.itemmemo,
                                                    expression:
                                                      "treeDataItem.itemmemo",
                                                  },
                                                ],
                                                attrs: {
                                                  cols: "40",
                                                  rows: "10",
                                                  maxlength: "3000",
                                                  placeholder:
                                                    _vm.$t("messages.note"),
                                                },
                                                domProps: {
                                                  value: treeDataItem.itemmemo,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      treeDataItem,
                                                      "itemmemo",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "memo-size",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("img", {
                                                    style: {
                                                      cursor: _vm.statusDisabled
                                                        ? "not-allowed"
                                                        : "pointer",
                                                    },
                                                    attrs: {
                                                      src: require("@/assets/static/icon/item-memo.svg"),
                                                      width: "16",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "delete-icon-div-parentmode",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                            staticStyle: {
                                              "margin-top": "3.5rem",
                                            },
                                            style: {
                                              cursor: _vm.statusDisabled
                                                ? "not-allowed"
                                                : "pointer",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteTreeProcess(
                                                  false,
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              treeDataItem.subassyFromId && !_vm.copyFlag
                                ? _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    _vm._l(
                                      _vm.callDataList,
                                      function (callDataItem, callIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: callIndex,
                                            staticClass: "process-input",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            treeDataItem.topSubassyId ===
                                            callDataItem.topSubassyId
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "process-body",
                                                    staticStyle: {
                                                      width: "100%",
                                                      display: "flex",
                                                      "background-color":
                                                        "#EBF9F5",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "katsudo-div-getdatamode",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-title",
                                                          },
                                                          [
                                                            _c(
                                                              "el-link",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#409EFF",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.callBackTreePop(
                                                                        callDataItem
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "messages.getData"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "katsudo-div-getdatamode",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.firstManagedProgram"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: "topItemName",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    callDataItem.topItemName
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "katsudo-div-getdatamode",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.managedProgramCount"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: "callNum",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    callDataItem.callNum
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "katsudo-div-getdatamode",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.updateUser"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: "callUpdaterName",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    callDataItem.callUpdaterName
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "katsudo-div-getdatamode",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.updateDate"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: "callUpddate",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatDate(
                                                                    callDataItem.callUpddate
                                                                  )
                                                                ) + " "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "katsudo-div-getdatamode",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.parentItem"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: "callParentNode",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    callDataItem.callParentNode
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "katsudo-div-getdatamode",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.intermediateProduct"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: "callName",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    callDataItem.seihinCallName +
                                                                      " " +
                                                                      callDataItem.callVersion
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "katsudo-div-getdatamode",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "item-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.emissionc"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop: "callEmisSum",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "block",
                                                                  "padding-left":
                                                                    "1rem",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.toScientificNum(
                                                                      callDataItem.callEmisSum
                                                                    )[0]
                                                                  ) +
                                                                    _vm._s(
                                                                      _vm.toScientificNum(
                                                                        callDataItem.callEmisSum
                                                                      )[1]
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c("sup", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.toScientificNum(
                                                                        callDataItem.callEmisSum
                                                                      )[2]
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "delete-icon-div-parentmode",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "3.5rem",
                                                          },
                                                          style: {
                                                            cursor:
                                                              _vm.statusDisabled
                                                                ? "not-allowed"
                                                                : "pointer",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteTreeProcess(
                                                                true,
                                                                callDataItem.topSubassyId,
                                                                callIndex
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            treeDataItem.topSubassyId ===
                                            callDataItem.topSubassyId
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-button-parentmode",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "new-item-button",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.callNewAddClick(
                                                              callDataItem,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "messages.addNewItem"
                                                            )
                                                          ) + " +"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.parentFlag === 2
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "new-item-button",
                                                            class:
                                                              treeDataItem.itemname
                                                                ? "data-call-enable"
                                                                : "surmise-process-disable",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.subDataCallClick(
                                                                  callDataItem,
                                                                  index,
                                                                  callIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.callParts"
                                                                )
                                                              ) + " +"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.parentFlag === 2
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "new-item-button",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.callDataCopyClick(
                                                                  callDataItem,
                                                                  index,
                                                                  callIndex
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "messages.copyLine"
                                                                )
                                                              ) + " +"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              treeDataItem.subassyFromId && _vm.copyFlag
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        display: "flex",
                                        "border-left": "5px solid #BFEBFA",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "komoku-div-parentmode",
                                          style: {
                                            width: _vm.noTreeFromCsvOrApi
                                              ? "15%"
                                              : "12%",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "messages.managedItem"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              index === 0
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      staticClass:
                                                        "suggestion-icon",
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        width: "200",
                                                        trigger: "hover",
                                                        content: _vm.$t(
                                                          "messages.itemContent"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".itemname",
                                                rules: _vm.newRules.itemname,
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "komoku-input",
                                                attrs: {
                                                  placeholder:
                                                    _vm.$t("messages.itemName"),
                                                  size: "small",
                                                  disabled: _vm.statusDisabled,
                                                },
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.itemNameFocus(
                                                      treeDataItem.itemname,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: treeDataItem.itemname,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      treeDataItem,
                                                      "itemname",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "treeDataItem.itemname",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "surmise-process",
                                              class:
                                                treeDataItem.itemname !== ""
                                                  ? "surmise-process-enable"
                                                  : "surmise-process-disable",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.surmiseTreeIdeaName(
                                                    treeDataItem.itemname,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("messages.EFAnalogy")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "komoku-div-parentmode",
                                          style: {
                                            width: _vm.noTreeFromCsvOrApi
                                              ? "15%"
                                              : "12%",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.process")
                                                  ) +
                                                  " "
                                              ),
                                              index === 0
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      staticClass:
                                                        "suggestion-icon",
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        trigger: "hover",
                                                        width: "200",
                                                        content: _vm.$t(
                                                          "messages.processContent"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".processcode",
                                                rules: _vm.newRules.processcode,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "select-item",
                                                  attrs: {
                                                    size: "small",
                                                    filterable: "",
                                                    disabled:
                                                      _vm.statusDisabled,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.addtreeDataGenprocess(
                                                        treeDataItem,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      treeDataItem.processcode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        treeDataItem,
                                                        "processcode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "treeDataItem.processcode",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.processnameList,
                                                  function (item, i) {
                                                    return _c("el-option", {
                                                      key: item.processCode + i,
                                                      attrs: {
                                                        label:
                                                          _vm.language ===
                                                            "en" &&
                                                          _vm.buttonFlag == 1 &&
                                                          _vm.parentFlag == 2
                                                            ? _vm.$t(
                                                                "messages." +
                                                                  item.processCode
                                                              ) +
                                                              "(" +
                                                              item.processCode +
                                                              ")"
                                                            : item.processName +
                                                              "(" +
                                                              item.processCode +
                                                              ")",
                                                        value: item.processCode,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "genntani-div-parentmode",
                                          style: {
                                            width: _vm.noTreeFromCsvOrApi
                                              ? "15%"
                                              : "12%",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.efName")
                                                  ) +
                                                  " "
                                              ),
                                              index === 0
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      staticClass:
                                                        "suggestion-icon",
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        width: "200",
                                                        trigger: "hover",
                                                        content:
                                                          _vm.$t(
                                                            "messages.efContent"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          treeDataItem.processcode !== "Z"
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    prop:
                                                      "treeDataItem." +
                                                      index +
                                                      ".displayname",
                                                    rules:
                                                      _vm.newRules.displayname,
                                                  },
                                                },
                                                [
                                                  _c("el-autocomplete", {
                                                    staticClass:
                                                      "genntani-input",
                                                    attrs: {
                                                      size: "small",
                                                      "fetch-suggestions":
                                                        _vm.querySearch,
                                                      placeholder:
                                                        _vm.$t(
                                                          "messages.peefn"
                                                        ),
                                                      "trigger-on-focus": false,
                                                      disabled:
                                                        _vm.statusDisabled,
                                                    },
                                                    on: {
                                                      select: function (item) {
                                                        _vm.newhandleSelect(
                                                          item,
                                                          index
                                                        )
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.newInputChange(
                                                          treeDataItem.displayname,
                                                          index
                                                        )
                                                      },
                                                      focus: function ($event) {
                                                        return _vm.newFocusChange(
                                                          index
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var item = ref.item
                                                            return [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.displayname
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        treeDataItem.displayname,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          treeDataItem,
                                                          "displayname",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "treeDataItem.displayname",
                                                    },
                                                  }),
                                                  treeDataItem.isDeleted
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#F56C6C",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.EFDelete"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !treeDataItem.isDeleted &&
                                                  treeDataItem.generationBoolean
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "orange",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.EFChanged"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !treeDataItem.isDeleted &&
                                                  !treeDataItem.generationBoolean &&
                                                  treeDataItem.unitDeleted
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "#F56C6C",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.subunitDelete"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !treeDataItem.isDeleted &&
                                                  !treeDataItem.generationBoolean &&
                                                  !treeDataItem.unitDeleted &&
                                                  treeDataItem.unitgenerationBoolean
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "orange",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "messages.subunitChanged"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "katsudo-div-parentmode",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "katsudo-hasLabel-div",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "item-title" },
                                                [
                                                  treeDataItem.processcode !==
                                                  "Z"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "messages.amount"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  treeDataItem.processcode ===
                                                  "Z"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "messages.emission"
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  index === 0
                                                    ? _c(
                                                        "el-popover",
                                                        {
                                                          staticClass:
                                                            "suggestion-icon",
                                                          attrs: {
                                                            placement:
                                                              "right-start",
                                                            width: "200",
                                                            trigger: "hover",
                                                            content: _vm.$t(
                                                              "messages.amountContent"
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-question",
                                                            attrs: {
                                                              slot: "reference",
                                                            },
                                                            slot: "reference",
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".quantity",
                                                rules: _vm.newRules.quantity,
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "katsudo-input",
                                                attrs: {
                                                  disabled: _vm.statusDisabled,
                                                  size: "small",
                                                  placeholder:
                                                    treeDataItem.processcode ===
                                                    "Z"
                                                      ? _vm.$t(
                                                          "messages.emission"
                                                        )
                                                      : _vm.$t(
                                                          "messages.amount"
                                                        ),
                                                  title: "",
                                                  type: "number",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.test2(
                                                      treeDataItem
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: treeDataItem.quantity,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      treeDataItem,
                                                      "quantity",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "treeDataItem.quantity",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "tanni-div-parentmode" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.unit")
                                                  ) +
                                                  " "
                                              ),
                                              index === 0
                                                ? _c(
                                                    "el-popover",
                                                    {
                                                      staticClass:
                                                        "suggestion-icon",
                                                      attrs: {
                                                        placement:
                                                          "right-start",
                                                        width: "200",
                                                        trigger: "hover",
                                                        content: _vm.$t(
                                                          "messages.unitContent"
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-question",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "unit" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder:
                                                      _vm.$t("messages.unit"),
                                                    size: "small",
                                                    disabled:
                                                      _vm.statusDisabled,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.unittreeChange(
                                                        treeDataItem.unit,
                                                        treeDataItem.unitList,
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: treeDataItem.unit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        treeDataItem,
                                                        "unit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "treeDataItem.unit",
                                                  },
                                                },
                                                _vm._l(
                                                  treeDataItem.unitList,
                                                  function (item, i) {
                                                    return _c("el-option", {
                                                      key: i,
                                                      attrs: {
                                                        value:
                                                          item.percent +
                                                          "/unitlabel/" +
                                                          item.tounit,
                                                        label: item.tounit,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "katsudo-div-parentmode",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "messages.intermediateProduct"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".interimproduct",
                                                rules:
                                                  _vm.newRules.interimproduct,
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "katsudo-input",
                                                attrs: {
                                                  disabled: _vm.statusDisabled,
                                                  size: "small",
                                                  maxlength: "40",
                                                },
                                                model: {
                                                  value:
                                                    treeDataItem.interimproduct,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      treeDataItem,
                                                      "interimproduct",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "treeDataItem.interimproduct",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.noTreeFromCsvOrApi
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "katsudo-div-parentmode",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "item-title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "messages.parentItem"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    prop:
                                                      "treeDataItem." +
                                                      index +
                                                      ".parentnode",
                                                    rules:
                                                      _vm.newRules.parentnode,
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass:
                                                      "katsudo-input",
                                                    attrs: {
                                                      disabled:
                                                        _vm.statusDisabled ||
                                                        !treeDataItem.itemname,
                                                      size: "small",
                                                      maxlength: "99",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeCallTempArr(
                                                          treeDataItem
                                                        )
                                                      },
                                                      focus: function ($event) {
                                                        return _vm.focusTempArr(
                                                          treeDataItem
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        treeDataItem.parentnode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          treeDataItem,
                                                          "parentnode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "treeDataItem.parentnode",
                                                    },
                                                  }),
                                                  treeDataItem.callParentShow
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "orange",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "親管理項目が正しいことを確認してください"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "rate-div-parentmode" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.yieldRate")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                prop:
                                                  "treeDataItem." +
                                                  index +
                                                  ".budomari",
                                                rules: _vm.newRules.budomari,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass: "katsudo-input",
                                                  attrs: {
                                                    disabled:
                                                      _vm.statusDisabled ||
                                                      !(
                                                        treeDataItem.processcode ===
                                                          "A1" ||
                                                        treeDataItem.processcode ===
                                                          "A2" ||
                                                        treeDataItem.processcode ===
                                                          "A3" ||
                                                        treeDataItem.genProcess ===
                                                          "A1" ||
                                                        treeDataItem.genProcess ===
                                                          "A2" ||
                                                        treeDataItem.genProcess ===
                                                          "A3"
                                                      ),
                                                    size: "small",
                                                    maxlength: "40",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.test2(
                                                        treeDataItem
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      treeDataItem.budomari,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        treeDataItem,
                                                        "budomari",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "treeDataItem.budomari",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        "font-style": "normal",
                                                        "margin-right": "5px",
                                                      },
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [_vm._v("%")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "emission-div-parentmode",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("messages.emissionc")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "emissionc" } },
                                            [
                                              treeDataItem.coefficient &&
                                              treeDataItem.budomari &&
                                              treeDataItem.budomari != "" &&
                                              !treeDataItem.isDeleted &&
                                              !treeDataItem.unitDeleted
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#E7E6E6",
                                                        display: "block",
                                                        "padding-left": "1rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.toScientificNum(
                                                            treeDataItem.quantity *
                                                              (treeDataItem.coefficient /
                                                                (parseFloat(
                                                                  treeDataItem.budomari ===
                                                                    "0"
                                                                    ? ""
                                                                    : treeDataItem.budomari
                                                                ) /
                                                                  100)) *
                                                              (isNaN(
                                                                Number(
                                                                  treeDataItem.transferUnitPercent
                                                                )
                                                              )
                                                                ? 1
                                                                : Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  ))
                                                          )[0]
                                                        ) +
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.quantity *
                                                                (treeDataItem.coefficient /
                                                                  (parseFloat(
                                                                    treeDataItem.budomari ===
                                                                      "0"
                                                                      ? ""
                                                                      : treeDataItem.budomari
                                                                  ) /
                                                                    100)) *
                                                                (isNaN(
                                                                  Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  )
                                                                )
                                                                  ? 1
                                                                  : Number(
                                                                      treeDataItem.transferUnitPercent
                                                                    ))
                                                            )[1]
                                                          )
                                                      ),
                                                      _c("sup", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.quantity *
                                                                (treeDataItem.coefficient /
                                                                  (parseFloat(
                                                                    treeDataItem.budomari ===
                                                                      "0"
                                                                      ? ""
                                                                      : treeDataItem.budomari
                                                                  ) /
                                                                    100)) *
                                                                (isNaN(
                                                                  Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  )
                                                                )
                                                                  ? 1
                                                                  : Number(
                                                                      treeDataItem.transferUnitPercent
                                                                    ))
                                                            )[2]
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : treeDataItem.coefficient &&
                                                  !treeDataItem.isDeleted &&
                                                  !treeDataItem.unitDeleted &&
                                                  !treeDataItem.budomari
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#E7E6E6",
                                                        display: "block",
                                                        "padding-left": "1rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.toScientificNum(
                                                            treeDataItem.quantity *
                                                              treeDataItem.coefficient *
                                                              (isNaN(
                                                                Number(
                                                                  treeDataItem.transferUnitPercent
                                                                )
                                                              )
                                                                ? 1
                                                                : Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  ))
                                                          )[0]
                                                        ) +
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.quantity *
                                                                treeDataItem.coefficient *
                                                                (isNaN(
                                                                  Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  )
                                                                )
                                                                  ? 1
                                                                  : Number(
                                                                      treeDataItem.transferUnitPercent
                                                                    ))
                                                            )[1]
                                                          )
                                                      ),
                                                      _c("sup", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.quantity *
                                                                treeDataItem.coefficient *
                                                                (isNaN(
                                                                  Number(
                                                                    treeDataItem.transferUnitPercent
                                                                  )
                                                                )
                                                                  ? 1
                                                                  : Number(
                                                                      treeDataItem.transferUnitPercent
                                                                    ))
                                                            )[2]
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : !treeDataItem.coefficient ||
                                                  treeDataItem.isDeleted ||
                                                  treeDataItem.unitDeleted
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#E7E6E6",
                                                        display: "block",
                                                        "padding-left": "1rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.toScientificNum(
                                                            treeDataItem.emissionc
                                                          )[0]
                                                        ) +
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.emissionc
                                                            )[1]
                                                          )
                                                      ),
                                                      _c("sup", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.toScientificNum(
                                                              treeDataItem.emissionc
                                                            )[2]
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item-memo-div-parentmode",
                                        },
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "right",
                                                disabled: _vm.statusDisabled,
                                              },
                                            },
                                            [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      treeDataItem.itemmemo,
                                                    expression:
                                                      "treeDataItem.itemmemo",
                                                  },
                                                ],
                                                attrs: {
                                                  cols: "40",
                                                  rows: "10",
                                                  maxlength: "3000",
                                                  placeholder:
                                                    _vm.$t("messages.note"),
                                                },
                                                domProps: {
                                                  value: treeDataItem.itemmemo,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      treeDataItem,
                                                      "itemmemo",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "memo-size",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("img", {
                                                    style: {
                                                      cursor: _vm.statusDisabled
                                                        ? "not-allowed"
                                                        : "pointer",
                                                    },
                                                    attrs: {
                                                      src: require("@/assets/static/icon/item-memo.svg"),
                                                      width: "16",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "delete-icon-div-parentmode",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                            staticStyle: {
                                              "margin-top": "3.5rem",
                                            },
                                            style: {
                                              cursor: _vm.statusDisabled
                                                ? "not-allowed"
                                                : "pointer",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteTreeProcess(
                                                  false,
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !treeDataItem.subassyFromId
                                ? _c(
                                    "div",
                                    { staticClass: "item-button-parentmode" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "new-item-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.newAddClick(
                                                treeDataItem,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("messages.addNewItem")
                                            ) + " +"
                                          ),
                                        ]
                                      ),
                                      _vm.parentFlag === 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "new-item-button",
                                              class: treeDataItem.itemname
                                                ? "data-call-enable"
                                                : "surmise-process-disable",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.dataCallClick(
                                                    treeDataItem.itemname,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("messages.callParts")
                                                ) + " +"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.parentFlag === 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "new-item-button",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.newCopyClick(
                                                    treeDataItem,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("messages.copyLine")
                                                ) + " +"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              treeDataItem.subassyFromId && _vm.copyFlag
                                ? _c(
                                    "div",
                                    { staticClass: "item-button-parentmode" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "new-item-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.newAddClick(
                                                treeDataItem,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("messages.addNewItem")
                                            ) + " +"
                                          ),
                                        ]
                                      ),
                                      _vm.callShow(
                                        treeDataItem.subassyFromId,
                                        index
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "new-item-button",
                                              class: treeDataItem.itemname
                                                ? "data-call-enable"
                                                : "surmise-process-disable",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.dataCallClick(
                                                    treeDataItem.itemname,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("messages.callParts")
                                                ) + " +"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "new-item-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.newCopyClick(
                                                treeDataItem,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("messages.copyLine")
                                            ) + " +"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "bottom-button-box padding-top-24 bom-import" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn-plain",
                    attrs: { disabled: _vm.statusDisabled },
                    on: { click: _vm.toBack },
                  },
                  [_vm._v(_vm._s(_vm.$t("messages.back")))]
                ),
                _vm.parentFlag !== 2 && !_vm.noTreeFromCsvOrApi
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn-color",
                        attrs: {
                          type: "primary",
                          disabled: _vm.statusDisabled,
                        },
                        on: { click: _vm.saveTemp },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.saveTemp")))]
                    )
                  : _vm._e(),
                _vm.parentFlag === 2 || _vm.noTreeFromCsvOrApi
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn-color",
                        attrs: {
                          type: "primary",
                          disabled: _vm.statusDisabled,
                        },
                        on: { click: _vm.saveTreeTemp },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.saveTemp")))]
                    )
                  : _vm._e(),
                _vm.parentFlag !== 2 && !_vm.noTreeFromCsvOrApi
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn-color",
                        attrs: {
                          type: "primary",
                          disabled: _vm.statusDisabled,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.doSave("subProcessItem", "seihinNameObj")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.calculate")))]
                    )
                  : _vm._e(),
                _vm.parentFlag === 2 || _vm.noTreeFromCsvOrApi
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn-color",
                        attrs: {
                          type: "primary",
                          disabled: _vm.statusDisabled,
                        },
                        on: { click: _vm.doSaveTree },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.calculate")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "",
                  visible: _vm.saveTempDialogVisible,
                  width: "30%",
                  center: "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.saveTempDialogVisible = $event
                  },
                },
              },
              [
                _vm.language === "jp"
                  ? _c("p", { staticClass: "text-line" }, [
                      _vm._v(
                        "指定された製品・バージョンは既に一時保存状態で登録済みのデータがあります。"
                      ),
                      _c("br"),
                      _vm._v(
                        " 製品一覧画面上の一時保存状態のデータを編集するプルダウンから選択し、排出量データの登録をしてください。 "
                      ),
                    ])
                  : _vm._e(),
                _vm.language === "en"
                  ? _c("p", { staticClass: "text-line" }, [
                      _vm._v(
                        "The entered product/version already has data registered in the finalized saved state."
                      ),
                      _c("br"),
                      _vm._v(
                        " Please open the edit screen from the reference screen of the product and edit the emissions data. "
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.saveTempDialogVisible = false
                          },
                        },
                      },
                      [_vm._v("OK")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "",
                  visible: _vm.saveFinalDialogVisible,
                  width: "30%",
                  center: "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.saveFinalDialogVisible = $event
                  },
                },
              },
              [
                _c("p", { staticClass: "text-line" }, [
                  _vm._v(_vm._s(_vm.$t("messages.tepvahdritfss"))),
                  _c("br"),
                  _vm._v(
                    " " + _vm._s(_vm.$t("messages.potesftrsofpaeted")) + " "
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.saveFinalDialogVisible = false
                          },
                        },
                      },
                      [_vm._v("OK")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "",
                  visible: _vm.deleteFinalDialogVisible,
                  width: "30%",
                  center: "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.deleteFinalDialogVisible = $event
                  },
                },
              },
              [
                _c("p", { staticClass: "text-line" }, [
                  _vm._v("同一製品・バージョンの削除データがあります。"),
                  _c("br"),
                  _vm._v(
                    " 同一製品の排出量を登録するには、別のバージョンを指定してください。 "
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.deleteFinalDialogVisible = false
                          },
                        },
                      },
                      [_vm._v("OK")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("messages.calQualityList"),
                  visible: _vm.centerDialogVisible,
                  "lock-scroll": false,
                  "append-to-body": true,
                  width: "70%",
                  center: "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.centerDialogVisible = $event
                  },
                  open: function ($event) {
                    return _vm.handleOpen()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "popup-height" },
                  [
                    _c("div", { staticClass: "check-note" }, [
                      _vm._v(_vm._s(_vm.$t("messages.check"))),
                    ]),
                    _vm._l(_vm.checkList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "popup-area" },
                        [
                          _c(
                            "div",
                            { staticClass: "check-area" },
                            [
                              _c("el-checkbox", {
                                model: {
                                  value: item.checkflag,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checkflag", $$v)
                                  },
                                  expression: "item.checkflag",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "check-content" },
                            [
                              _c(
                                "el-collapse",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "check-list-title check-break-line",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.checkitem.replaceAll("\\n", "\n")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-collapse-item",
                                    { attrs: { name: "1" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-color check-break-line",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.checkitemComment.replaceAll(
                                                "\\n",
                                                "\n"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-plain",
                        on: {
                          click: function ($event) {
                            _vm.centerDialogVisible = false
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.back")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-color",
                        attrs: { type: "primary" },
                        on: { click: _vm.closeDialog },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.done")))]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "85%",
                  title: _vm.$t("messages.EFAnalogy"),
                  visible: _vm.surmiseShow,
                  center: "",
                  "append-to-body": "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.surmiseShow = $event
                  },
                },
              },
              [
                _vm.unregisteredEFShow
                  ? _c("span", { staticClass: "text-line1" }, [
                      _vm._v(
                        "過去にこの管理項目名で登録された原単位はありません"
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "el-table",
                  {
                    staticClass: "border-1 margin-top-20",
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.surmiseIdeaData, height: "261" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "name", label: _vm.$t("messages.eF") },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "colum-width surmise-idea-name",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectSurmiseIdea(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.name))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "seihinnameVersion",
                        label: _vm.$t("messages.abProductVersion"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", { staticClass: "colum-width" }, [
                                _vm._v(
                                  _vm._s(scope.row.seihinname) +
                                    " " +
                                    _vm._s(scope.row.version)
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "updatername",
                        label: _vm.$t("messages.lastUpdatedBy1"),
                        width: "260",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", { staticClass: "colum-width" }, [
                                _vm._v(_vm._s(scope.row.updatername)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "upddate",
                        label: _vm.$t("messages.lastUpdate"),
                        width: "130",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", { staticClass: "colum-width" }, [
                                _vm._v(
                                  _vm._s(_vm.formatDate(scope.row.upddate))
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  width: "30%",
                  visible: _vm.isShowNoTrafficPopup,
                  center: "",
                  "append-to-body": "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.isShowNoTrafficPopup = $event
                  },
                },
              },
              [
                _c("span", { staticClass: "text-line2" }, [
                  _vm._v(_vm._s(_vm.$t("messages.EFUnconvertible"))),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-plain",
                        on: {
                          click: function ($event) {
                            _vm.isShowNoTrafficPopup = false
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("messages.backName")))]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm.isShowTrafficPopup
              ? _c("trafficPopup", {
                  attrs: {
                    "button-name": _vm.buttonName,
                    language: _vm.language,
                    backName: _vm.backName,
                    transportMeans: _vm.transportMeans,
                    transportDistance: _vm.transportDistance,
                    "name-array": _vm.nameArray,
                    "tkm-name-list": _vm.tkmNameList,
                    "traffic-item-weight": _vm.trafficItemWeight,
                    "traffic-unit-list": _vm.trafficUnitList,
                    "traffic-unit": _vm.trafficUnit,
                  },
                  on: { childFn: _vm.parentFn4TrafficCalculator },
                })
              : _vm._e(),
            _vm.isShowUtilityPopup
              ? _c("utilityPopup", {
                  attrs: { "button-name": _vm.buttonName },
                  on: { childFn: _vm.parentFn4UtilityCalculator },
                })
              : _vm._e(),
            _vm.isShowPopup
              ? _c("popup", {
                  attrs: {
                    "is-show-popup": _vm.isShowPopup,
                    processlist4select: _vm.processList4select,
                  },
                  on: {
                    showPopupEvent: _vm.addProcess,
                    getAddProcessDataEvent: _vm.getAddProcessFromPopup,
                  },
                })
              : _vm._e(),
            _vm.isShowComfirmPopup
              ? _c("confirmPopup", {
                  attrs: {
                    contentmessage: _vm.popupMessage,
                    "button-name": _vm.buttonName,
                    cancelName: _vm.cancelName,
                  },
                  on: { childFn: _vm.parentFn },
                })
              : _vm._e(),
            _vm.isShowAlertPopup
              ? _c("alertPopup", {
                  attrs: {
                    contentmessage: _vm.popupMessage,
                    "button-name": _vm.buttonName,
                  },
                  on: { childFn: _vm.parentFn4Alert },
                })
              : _vm._e(),
            _c("callPopup", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowCallPopup,
                  expression: "isShowCallPopup",
                },
              ],
              ref: "callBackPopup",
              attrs: {
                kigyouCode: _vm.kigyouCode,
                "seihin-call-list": _vm.seihinCallList,
                callOriprocessFlg: _vm.callOriprocessFlg,
                "back-flag": _vm.backFlag,
                "temp-call-data-list": _vm.tempCallDataList,
              },
              on: { childFn: _vm.parentFnCallData },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }