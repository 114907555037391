<template>
  <transition name="popup-fade">
    <div
      class="popup-body"
    >
      <div class="popup-content-wrap">
        <div class="popup">
          <div class="title-box">
            <i class="el-icon-close" @click="doClose" />
          </div>
          <div v-show="!backFlag" class="title-text">{{ $t('messages.enterProduct') }}</div>
          <div v-show="backFlag" class="title-text">{{ $t('messages.callData') }}</div>
          <div class="header-layout">
            <el-row :gutter="16" class="width-33">
              <el-col :span="18">
                <div class="production-item">
                  <div class="content-layout">
                    <span class="margin-right-16">{{ $t('messages.productAndVersion') }}</span>
                    <el-popover placement="right" trigger="click">
                      <textarea v-model="seihinCallNote" readonly="true" cols="40" rows="10" maxlength="3000" placeholder="メモを記載" />
                      <span slot="reference" class="icon-note"><img src="@/assets/static/icon/note.svg"></span>
                    </el-popover>
                  </div>
                  <el-form ref="selectValueDefaultForm" :model="selectValueDefaultForm" :rules="selectValueDefaultRules" style="width: 100%">
                    <el-form-item v-show="!backFlag" prop="selectValueDefault">
                      <el-select v-model="selectValueDefaultForm.selectValueDefault" :title="temptitle" placeholder="製品を選択してください" class="seihin-select" size="mini" filterable @change="changeSeihin">
                        <el-option
                          v-for="item in seihinCallList"
                          :key="item.seihincode + ' ' + item.version"
                          :label="item.version ? `${item.seihinname} ${item.version}` : item.seihinname"
                          :value="item.seihincode + ' ' + item.version"
                          :disabled="item.disabled"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item v-show="backFlag" prop="selectValueDefault">
                      <el-select v-model="selectValueDefaultForm.selectValueDefault" :title="temptitle" placeholder="製品を選択してください" class="seihin-select" size="mini" disabled @change="changeSeihin">
                        <el-option
                          v-for="item in seihinCallList"
                          :key="item.seihincode + ' ' + item.version"
                          :label="item.version ? `${item.seihinname} ${item.version}` : item.seihinname"
                          :value="item.seihincode + ' ' + item.version"
                          :disabled="item.disabled"
                        />
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="production-item">
                  <div style="height: 20px">{{ $t('messages.unit') }}</div>
                  <el-input :value="seihinCallUnit" size="mini" style="line-height: 4.3rem" disabled />
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="table-container">
            <!-- <el-table class="self-tree-table" size="small" :data="[{}]" height="400" :span-method="arraySpanMethod"> -->
            <el-table class="self-tree-table" size="small" :data="[{}]" :span-method="arraySpanMethod">
              <!-- <template > -->
              <el-table-column
                prop="itemName"
                label="項目"
                min-width="30%"
                style="padding:0rem 0rem 0rem 8rem"
              >
                <template #header>
                  <i :class="{'el-icon-arrow-down': showAll, 'el-icon-arrow-right': !showAll}" style="padding-left: 6%;" @click="handleShowAll()" />
                  <i style="padding-right:10px" />
                  <span class="table-title">{{ $t('messages.iItem') }}</span>
                </template>
                <template>
                  <table v-if="tableData[0] && !tableData[0].detail && !tableData[0].child" style="width:100%;" border="0" cellpadding="0" cellspacing="0">
                    <tr style="background-color: #000;color:#fff;height: 4rem">
                      <td style="background-color: white !important;width: 2%" />
                      <td style="width: 30%">
                        <i style="padding-right:24px" />
                        合計
                      </td>
                      <td style="width: 18%" />
                      <td style="width: 20%" />
                      <td style="width: 6%; text-align: center;" />
                      <td style="width: 5%" />
                      <td style="width: 7%">{{ toScientificNum(tableData[0].total)[0] }} {{ toScientificNum(tableData[0].total)[1] }} <sup>{{ toScientificNum(tableData[0].total)[2] }}</sup></td>
                      <td style="width: 5%; text-align: center;">{{ tableData[0].pre }}%</td>
                      <td style="width: 7%" />
                    </tr>
                  </table>
                  <div class="table-scroll">
                    <span v-for="(row, index) in tableData" :key="index">
                      <callTree v-if="row.detail || row.child" ref="backCallTree" :show-all="showAll" :row="row" :level-num="0" :checkSubassyId="row.detail[0].subassyId" />
                    </span>
                  </div>
                </template>
              </el-table-column>
              <!-- </template> -->
              <el-table-column
                prop="processName"
                :label="$t('messages.process')"
                min-width="20%"
              />
              <el-table-column
                prop="ideaName"
                :label="$t('messages.eF')"
                min-width="20%"
              />
              <el-table-column
                prop="quantity"
                :label="$t('messages.amount')"
                min-width="6%"
              />
              <el-table-column
                prop="unit"
                :label="$t('messages.unit')"
                min-width="5%"
              />
              <el-table-column
                prop="emissionc"
                :label="$t('messages.emission')"
                min-width="7%"
              />
              <el-table-column
                prop="pre"
                :label="$t('messages.ratio')"
                min-width="5%"
              />
              <el-table-column
                prop="budomari"
                :label="$t('messages.yieldRate')"
                min-width="7%"
              />
            </el-table>
          </div>
          <div class="tab-button-box">
            <el-button class="back-button" @click="doClose">{{ $t('messages.backName') }}</el-button>
            <el-button v-show="!backFlag" type="primary" @click="doComfirm">{{ $t('messages.confirm') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
import { getVersionMasterAPI } from '@/api/browser'
import { getTreeDetailAPI } from '@/api/input'
import callTree from '@/views/browser/callTree.vue'
import { EventBus } from '@/main'
import { times, divide } from '../../../utils/number'
export default {
  name: 'CallPopup',
  components: {
    'callTree': callTree
  },
  props: {
    isShowCallPopup: Boolean,
    callOriprocessFlg: Boolean,
    kigyouCode: {
      type: String,
      default: ''
    },
    seihinCallList: {
      type: Array,
      default: () => {}
    },
    backFlag: {
      type: String,
      default: ''
    },
    tempCallDataList: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      callVersion: '',
      seihinCallCode: '',
      seihinCallUnit: '',
      seihinCallName: '',
      seihinCallNote: '',
      selectValueDefaultForm: {
        selectValueDefault: ''
      },
      selectValueDefaultRules: {
        selectValueDefault: [
          { required: true, message: '製品を選択してください', trigger: 'blur' }
        ]
      },
      temptitle: '',
      callUpdaterName: '',
      callUpddate: 0,
      callDataList: [{
        callUpdaterName: '',
        callUpddate: 0,
        callNum: 0,
        callEmisSum: 0,
        seihinCallName: '',
        callVersion: '',
        topItemName: '',
        seihinCallCode: '',
        seihinCallUnit: '',
        itemmemo: ''　//　登録の時に、呼び出しデータは管理項目メモがないので、空にする 2024.12.09 Ubicom ビリー　追加
      }],
      showAll: false,
      tableData: [],
      callNum: 0,
      callEmisSum: 0,
      callIndex: 0,
      subassyIdArr: [],
      oneDetailLength: 0,
      topItemName: ''
    }
  },
  mounted() {
    EventBus.$on('toCallNumAdd', (callNum) => {
      this.callNum = callNum
    })
    EventBus.$on('toCallEmisSumAdd', (callEmisSum) => {
      this.callEmisSum = callEmisSum
    })
    EventBus.$on('toSubIdArrAdd', (subassyIdArr) => {
      this.subassyIdArr = subassyIdArr
    })
    EventBus.$on('toOneLengthCallPop', (oneDetailLength) => {
      this.oneDetailLength = oneDetailLength
    })
    EventBus.$on('toCallPop', (topItemName) => {
      this.topItemName = topItemName
    })
  },
  beforeDestroy() {
    EventBus.$off(['toCallNumAdd', 'toCallEmisSumAdd', 'toSubIdArrAdd', 'toOneLengthCallPop', 'toCallPop'])
  },
  methods: {
    changeSeihin(val) {
      this.callVersion = this.seihinCallList
        .filter(item => item['seihincode'] + ' ' + item['version'] === val)[0]['version']
      this.seihinCallCode = this.seihinCallList
        .filter(item => item['seihincode'] + ' ' + item['version'] === val)[0]['seihincode']
      this.seihinCallUnit = this.seihinCallList
        .filter(item => item['seihincode'] + ' ' + item['version'] === val)[0]['seihinunit']
      this.seihinCallName = this.seihinCallList
        .filter(item => item['seihincode'] + ' ' + item['version'] === val)[0]['seihinname']
      this.callUpdaterName = this.seihinCallList
        .filter(item => item['seihincode'] + ' ' + item['version'] === val)[0]['updatername']
      this.callUpddate = this.seihinCallList
        .filter(item => item['seihincode'] + ' ' + item['version'] === val)[0]['upddate']
      getVersionMasterAPI(this.seihinCallCode, this.callVersion).then(
        res => {
          if (res.code === 200) {
            this.seihinCallNote = res.data.remark
          } else {
            this.rejectMsg()
          }
        }
      ).catch(
        () => {
          console.error('ツリー製品を取得の際に、エラーが発生しました')
        }
      )
      this.getTreeDetail(this.kigyouCode, this.callVersion, this.seihinCallCode, this.callOriprocessFlg)
      this.$store.commit('CHANGE_SEHIN', true)
    },
    rejectMsg() {
      this.$message.error('ネットワークエラーが発生した。')
    },
    doComfirm() {
      let tempCallDataList1 = {}
      if (this.callIndex === 0) {
        this.callDataList[0].callUpdaterName = this.callUpdaterName
        this.callDataList[0].callUpddate = this.callUpddate
        this.callDataList[0].callNum = this.callNum
        this.callDataList[0].callEmisSum = this.callEmisSum
        this.callDataList[0].seihinCallName = this.seihinCallName
        this.callDataList[0].callVersion = this.callVersion
        this.callDataList[0].topItemName = this.topItemName
        this.callDataList[0].seihinCallCode = this.seihinCallCode
        this.callDataList[0].seihinCallUnit = this.seihinCallUnit
        this.callIndex++
      } else {
        tempCallDataList1 = { callUpdaterName: this.callUpdaterName, callUpddate: this.callUpddate, callNum: this.callNum, callEmisSum: this.callEmisSum, seihinCallName: this.seihinCallName, callVersion: this.callVersion, topItemName: this.topItemName, seihinCallCode: this.seihinCallCode, seihinCallUnit: this.seihinCallUnit }
        this.callDataList = this.tempCallDataList
        this.callDataList.splice(this.callDataList.length, 0, tempCallDataList1)
      }
      this.$emit('childFn', { 'isShowCallPopup': false, 'subassyIdArr': this.subassyIdArr, 'callDataList': this.callDataList, 'oneDetailLength': this.oneDetailLength })
      this.tableData = []
      this.seihinCallUnit = ''
      this.$refs['selectValueDefaultForm'].resetFields()
    },
    doClose() {
      this.$emit('childFn', { 'isShowCallPopup': false })
      this.tableData = []
      this.seihinCallUnit = ''
      this.$refs['selectValueDefaultForm'].resetFields()
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return [1, 8]
      }
      return [0, 0]
    },
    handleShowAll() {
      this.showAll = !this.showAll
    },
    toScientificNum(num) {
      num = Number(num)
      if (!num) {
        num = 0
      }
      var eformat = num.toExponential()
      var tmpArray = eformat.match(/(.?\d\.?\d*)e(.?\d*)/)
      tmpArray[1] = Math.round(times(tmpArray[1], 100)) / 100
      tmpArray[1] = parseFloat(tmpArray[1]).toFixed(2)
      tmpArray[2] = tmpArray[2].replace('+', '')
      var ScientificNum
      if (tmpArray[2] !== '0') {
        ScientificNum = [tmpArray[1], 'x10', tmpArray[2]]
      } else {
        ScientificNum = [tmpArray[1]]
      }
      return ScientificNum
    },
    getTreeDetail(kigyouCode, callVersion, seihinCallCode, callOriprocessFlg) {
      getTreeDetailAPI(kigyouCode, callVersion, seihinCallCode, callOriprocessFlg).then(
        res => {
          if (res.code === 200) {
            this.tableData = res.data
          } else {
            this.rejectMsg()
          }
        }
      ).catch(
        (e) => {
          console.error('ツリー製品を取得の際に、エラーが発生しました', e)
        }
      )
    },
    backChangeSeihin(backTopSubassyId, backCallVal, backCallUnit, backCallCode, backVersion) {
      this.selectValueDefaultForm.selectValueDefault = backCallVal
      this.seihinCallUnit = backCallUnit
      this.seihinCallCode = backCallCode
      this.callVersion = backVersion
      getVersionMasterAPI(this.seihinCallCode, this.callVersion).then(
        res => {
          if (res.code === 200) {
            this.seihinCallNote = res.data.remark
          } else {
            this.rejectMsg()
          }
        }
      ).catch(
        () => {
          console.error('ツリー製品を取得の際に、エラーが発生しました')
        }
      )
      this.getTreeDetail(this.kigyouCode, this.callVersion, this.seihinCallCode, this.callOriprocessFlg)
      this.$store.commit('SET_SUB_ID', backTopSubassyId)
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .popup-content-wrap {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    // height: 69%;
    height: 475px;
    width: 95%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    z-index: 999;
    user-select: none;

    .popup {

      .title-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1rem;
        margin-right: 2%;
        height: 10%;

        .el-icon-close {
          cursor: pointer;
        }
      }
      .title-text {
        font-family: 'NotoSansCJKjp-Medium';
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
        text-align: left;
        font-weight: 500;
        margin-left: 2rem;
      }
      .header-layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        margin: 1.5rem 2rem 0rem 2rem;
      }
      .production-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        // height: 10%;
        height: 65px;
        width: 100%;
        font-size: 1.4rem;
        .seihin-select {
            width: 100%;
        }
      }
      .content-layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 20px;
      }
      .margin-right-16 {
        margin-right:1.6rem;
      }
      .icon-note {
        cursor: pointer;
        display: inline-block;
        padding-top: 3px;
      }

      .table-container {
        // margin: 0rem 2% 0rem 2%;
        height: 280px;
        .self-tree-table {
          // height: 100%;
          .el-table__body tr:hover>td.el-table__cell {
              background-color: unset !important;
          }
          .table-scroll {
              max-height: 180px;
              overflow-y: scroll;
          }
        }
      }

      .tab-button-box{
        // margin-top: 0.7%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // opacity: 0.85;
        background: #FFFFFF;
        height: 20%;
        margin-top: 10px;

        .back-button {
          font-family: 'NotoSansCJKjp-Regular';
          // font-size: 12px;
          // color: #333333;
          letter-spacing: 0;
          font-weight: 400;
          // border: 1px solid #333333;
          border-radius: 4px;
        }
      }
    }
  }
}

/* 进入和出去的动画 */
.popup-fade-enter-active {
  animation: opacity 0.3s;
}
.popup-fade-enter-active .popup-content-wrap {
  animation: scale 0.3s;
}
.popup-fade-leave-active {
  animation: outOpacity 0.2s;
}

.el-form-item__content {
    line-height: 4.3rem;
}

/*滚动条样式*/
.process-list::-webkit-scrollbar {
  width: 10px;
  /*height: 4px;*/
}
.process-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);

  background: #CCCCCC;
  border-radius: 4px;
}
.process-list::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);

}

/* 进来的动画 */
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
