<template>
  <transition name="popup-fade">
    <div
      class="popup-body"
    >
      <div class="popup-content-wrap">
        <div class="popup">
          <div class="title-box">
            <i class="el-icon-close" @click="doClose" />
            <span class="title-font">{{ $t('messages.newProcess') }}</span>
            <el-popover
              placement="right-start"
              width="200"
              class="suggestion-icon"
              trigger="hover"
              :content="processlist4select.length > 0 && processlist4select[0].icon ? content : originalContent "
            >
              <i slot="reference" class="el-icon-question" />
            </el-popover>
          </div>
          <div v-if="processlist4select.length > 0 && processlist4select[0].icon" class="process-list-box">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                v-for="(item) in elTabsData"
                :key="item.name"
                :label="item.title"
                :name="item.name"
              >
                <div class="process-list">
                  <div v-for="(subProcess, subProcessIndex) in processlist4select" v-show="subProcess.isShow" :key="subProcessIndex" class="process-item" @click="activeBtn(subProcess)">
                    <div class="process-icon" :style="getStyle(subProcess)">
                      <i v-if="subProcess.isActive" class="el-icon-success" />
                      <img v-if="subProcess && subProcess.icon" :src="getIconUrl(subProcess)">
                      <div class="icon-font">{{ subProcess.processCode }}: {{ $t('messages.' + subProcess.processCode ) }}</div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs></div>
          <div v-else class="process-list-box">
            <el-tabs v-model="activeName">
              <el-tab-pane
                v-for="(item) in [{ 'title': this.$t('messages.all'), 'name': 'all' }]"
                :key="item.name"
                :label="item.title"
                :name="item.name"
              >
                <div class="process-list">
                  <div v-for="(subProcess, subProcessIndex) in processlist4select" v-show="subProcess.isShow" :key="subProcessIndex" class="process-item" @click="activeBtn(subProcess)">
                    <div class="process-icon" :style="getStyle(subProcess)">
                      <i v-if="subProcess.isActive" class="el-icon-success" />
                      <img v-if="subProcess && subProcess.icon" :src="getIconUrl(subProcess)">
                      <div class="icon-font">{{ subProcess.processCode }}: {{ subProcess.processName }}</div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs></div>
          <div class="tab-button-box">
            <el-button class="back-button" @click="doClose"> {{ $t('messages.processCancel') }}</el-button>
            <el-button
              type="primary"
              class="calculator-button"
              @click="sendProcess2update"
            >
              {{ $t('messages.processAdd') }}
              <i class="el-icon-check" />
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'Popup',
  props: {
    isShowPopup: Boolean,
    processlist4select: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeName: 'all',
      childIsShowPopup: this.isShowPopup,
      addProcess: {},
      elTabsData: [
        { 'title': this.$t('messages.all'), 'name': 'all' },
        { 'title': this.$t('messages.a1ToA3'), 'name': 'a1a3' },
        { 'title': this.$t('messages.a4ToA5'), 'name': 'a4a5' },
        { 'title': this.$t('messages.b1ToB7'), 'name': 'b1b7' },
        { 'title': this.$t('messages.c1ToC4'), 'name': 'c1c14' },
        { 'title': this.$t('messages.dTitle'), 'name': 'd' },
        // { 'title': 'Z:その他', 'name': 'z' }
      ],
      mapping: {
        '0': ['A1', 'A2', 'A3', 'A4', 'A5', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7',
          'C1', 'C2', 'C3', 'C4', 'D1'],
        '1': ['A1', 'A2', 'A3'],
        '2': ['A4', 'A5'],
        '3': ['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7'],
        '4': ['C1', 'C2', 'C3', 'C4'],
        '5': ['D'],
        '6': ['Z']
      },
      content: '今回、算定の対象とするプロセスを選択してください。対象外のプロセスは選択する必要はありません。原則として、A1、A2、A3は選択してください。',
      originalContent: '今回、算定の対象とするプロセスを選択してください。対象外のプロセスは選択する必要はありません。'
    }
  },
  watch: {
    childIsShowPopup(val) {
      this.isShowPopup = val
    },
    '$i18n.locale'() {
      // this.language = this.$i18n.locale
      this.elTabsData = [
        { 'title': this.$t('messages.all'), 'name': 'all' },
        { 'title': this.$t('messages.a1ToA3'), 'name': 'a1a3' },
        { 'title': this.$t('messages.a4ToA5'), 'name': 'a4a5' },
        { 'title': this.$t('messages.b1ToB7'), 'name': 'b1b7' },
        { 'title': this.$t('messages.c1ToC4'), 'name': 'c1c14' },
        { 'title': this.$t('messages.dTitle'), 'name': 'd' },
        // { 'title': 'Z:その他', 'name': 'z' }
      ]
    }
  },
  methods: {
    handleClick(tab) {
      for (var process of this.processlist4select) {
        if (this.mapping[tab.index].indexOf(process.processCode) < 0) {
          process['isShow'] = false
        } else {
          process['isShow'] = true
        }
      }
    },
    activeBtn(subProcess) {
      subProcess.isActive = !subProcess.isActive
    },
    getStyle(subProcess) {
      if (subProcess.isActive) {
        return 'background: #FAFAFA'
      }
    },
    // [プロセスを追加する]を押すと呼び出される
    sendProcess2update() {
      this.addProcess = this.processlist4select.filter(item => item.isActive)
      for (const index in this.addProcess) {
        this.$set(this.addProcess[index], 'index', 0)
        this.$set(this.addProcess[index], 'kigyoucode', '')
        this.$set(this.addProcess[index], 'seihincode', '')
        this.$set(this.addProcess[index], 'seihinname', '')
        this.$set(this.addProcess[index], 'processname4display', '')
        this.$set(this.addProcess[index], 'itemname', '')
        this.$set(this.addProcess[index], 'ideacode', '')
        this.$set(this.addProcess[index], 'coefficient', this.addProcess[index].processCode === 'Z' ? 1 : '')
        this.$set(this.addProcess[index], 'quantity', '')
        this.$set(this.addProcess[index], 'emissionc', 0)
        this.$set(this.addProcess[index], 'percentage', 0)
        this.$set(this.addProcess[index], 'delFlg', '')
        this.$set(this.addProcess[index], 'upduser', '')
        this.$set(this.addProcess[index], 'upddate', '')
        this.$set(this.addProcess[index], 'name', '')
        this.$set(this.addProcess[index], 'interimproduct', '')
        this.$set(this.addProcess[index], 'unit', this.addProcess[index].processCode === 'Z' ? 'kg-CO₂eq' : '')
        this.$set(this.addProcess[index], 'processname', this.addProcess[index].processName)
        this.$set(this.addProcess[index], 'processcode', this.addProcess[index].processCode)
        this.$set(this.addProcess[index], 'genProcess', this.addProcess[index].genProcess)
        this.$set(this.addProcess[index], 'budomari', '')
        this.$set(this.addProcess[index], 'displayGenProcess', this.addProcess[index].genProcess ? this.addProcess[index].genProcess : this.addProcess[index].processCode)
        // use for original 実績値 show
        this.$set(this.addProcess[index], 'originalIcon', 'originalIcon')
        this.$delete(this.addProcess[index], 'isActive')
        this.$delete(this.addProcess[index], 'isShow')
        this.$delete(this.addProcess[index], 'processCode')
        this.$delete(this.addProcess[index], 'processName')
        this.$set(this.addProcess[index], 'itemmemo', '') // 管理項目メモ対応  2024.12.09 Ubicom ビリー 追加
      }

      this.$emit('getAddProcessDataEvent', this.addProcess)
      this.$emit('showPopupEvent', false)
      this.activeName = 'all'
    },
    doClose() {
      this.$emit('showPopupEvent', false)
      this.activeName = 'all'
    },
    getIconUrl(subProcess) {
      return require('@/assets/static/process/' + subProcess.icon)
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  /* 这里防止当用户长按屏幕，出现的黑色背景色块，以及 iPhone 横平时字体的缩放问题 */
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .popup-content-wrap {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    // height: 70%;
    width: 65%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    z-index: 999;
    user-select: none;

    .popup {

      .title-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 3%;
        margin-bottom: 1%;
        margin-left: 3%;

        .suggestion-icon {
          color: #52C433;
        }

        .el-icon-close {
          cursor: pointer;
        }

        .title-font {
          font-family: 'NotoSansCJKjp-Medium';
          font-size: 20px;
          color: #333333;
          letter-spacing: 0;
          text-align: center;
          font-weight: 500;
          margin-left: 3%;
        }
      }

      .process-list-box {
        margin-left: 3%;
        margin-right: 3%;
        height: 100%;
        width: 95%;
        font-size: 14px;

        .process-list {
          max-height: 200px;
          display: flex;
          flex-wrap: wrap;
          overflow-y: auto;
          overflow-x: auto;
          width: 100%;

          .process-item {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: stretch;
              margin-top: 4px;
              height: 100%;
              width: 25%;

            .process-icon {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position:relative;
              height: 100px;
              width: 100%;
              background: none repeat scroll 0 0 white;
              cursor: pointer;
              border: 1px solid #D6D6D6;
              border-radius: 8px;
              margin-right: 0.3rem;
              margin-bottom: 0.3rem;
              font-family: 'NotoSansCJKjp-Regular';
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;

              .process-icon:focus {
                background-color: #FAFAFA;
              }

              .el-icon-success {
                position: absolute;
                top: 3%;
                left: 4%;
              }

              .icon-font {
                margin-top: 4%;
                font-family: 'NotoSansCJKjp-Regular';
                font-size: 14px;
                color: #333333;
                letter-spacing: 0;
                font-weight: 400;
              }
            }
          }
        }
      }

      .tab-button-box{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // opacity: 0.85;
        // background: #FFFFFF;
        margin-bottom: 24px;

        .back-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 12px;
          // color: #333333;
          letter-spacing: 0;
          font-weight: 400;
          // border: 1px solid #333333;
          border-radius: 4px;
          margin-top: 24px;
        }

        .calculator-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-weight: 400;
          // background: #333333;
          border-radius: 4px;
          margin-top: 24px;
        }
      }
    }
  }
}

/* 进入和出去的动画 */
.popup-fade-enter-active {
  animation: opacity 0.3s;
}
.popup-fade-enter-active .popup-content-wrap {
  animation: scale 0.3s;
}
.popup-fade-leave-active {
  animation: outOpacity 0.2s;
}

/*滚动条样式*/
.process-list::-webkit-scrollbar {
  width: 10px;
  /*height: 4px;*/
}
.process-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);

  background: #CCCCCC;
  border-radius: 4px;
}
.process-list::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);

}

/* 进来的动画 */
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* 出去的动画 */
@keyframes outOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
